export default {
  title: "Termini privacy ed utilizzo cookie",
  read: "Ho letto e accetto le condizioni e i termini di utilizzo",
  text_nominale_footer: `
    <p>
    PlayYourJob è una piattaforma di Laborplay S.r.l., P.Iva 06563810487
    con sede legale in: Via Madonna del Piano n. 6, Sesto Fiorentino (FI)
    email:&#32; 
    <a href="mailto:privacy@laborplay.com">privacy@laborplay.com</a> ;
    pec:&#32;
    <a href="mailto:laborplay@legalmail.it">laborplay@legalmail.it</a>.
    </p>
    <h5>
    INFORMATIVA SUL TRATTAMENTO DEI DATI PERSONALI AI SENSI DELL’ART. 13
    DEL REGOLAMENTO UE 2016/679 (RGPD)
    </h5>
    <h6>1. Titolare e responsabili del trattamento</h6>
    <p>
    Titolare del trattamento è: Laborplay S.r.l., P. Iva 06563810487, con
    sede legale in: Via Madonna del Piano n. 6, Sesto Fiorentino (FI) al
    quale ci si potrà rivolgere per esercitare i diritti riconosciuti dal
    RGPD e per conoscere l'elenco aggiornato di tutti i Responsabili del
    trattamento dei dati eventualmente nominati.
    </p>
    <p>Il Legale Rappresentante è: Elena Gaiffi.</p>
    <p>
    Il Titolare del trattamento può essere contattato ai seguenti
    recapiti:
    </p>
    <p>email: privacy@laborplay.com; pec: laborplay@legalmail.it.</p>
    <p>
    Il Titolare del trattamento ha nominato come Responsabile della
    Protezione dei Dati personali (“R.P.D.”) l’Ing. Andrea Appicciafuoco
    (email: rpd.appicciafuoco@gmail.com).
    </p>
    <h6>2. Finalità del trattamento</h6>
    <p>
    I dati personali forniti potranno essere trattati unicamente per le
    seguenti finalità: esecuzione del servizio offerto dal Titolare; invio
    di comunicazioni di servizio; adempimento degli obblighi di legge ed
    ottemperanza alle richieste provenienti dall'autorità.
    </p>
    <p>
    Non sono previsti ulteriori trattamenti basati sui legittimi interessi
    perseguiti dal Titolare del trattamento.
    </p>
    <h6>3. Base Giuridica del Trattamento</h6>
    <p>
    Il Titolare tratta i dati personali lecitamente laddove il
    trattamento:
    </p>
    <ul>
    <li>
        sia necessario all’esecuzione di un contratto di cui l’interessato
        sia parte o all’esecuzione di misure precontrattuali adottate su
        richiesta dell’interessato;
    </li>
    <li>
        si basi sul consenso espresso dell’interessato per una o più
        finalità;
    </li>
    <li>
        sia necessario per adempiere ad un obbligo legale al quale il
        Titolare è soggetto.
    </li>
    </ul>
    <h6>4. Tipi di dati trattati</h6>
    <p>
    Dati comuni (a titolo puramente esemplificativo: anno di nascita,
    genere).
    </p>
    <p>
    Non verranno trattati i dati di soggetti di età inferiore a quella
    minima prevista dalla normativa vigente (minori). Il Titolare del
    trattamento non fa utilizzo di cookies e pertanto non verranno
    trattati dati provenienti dall’utilizzo degli stessi.
    </p>
    <h6>5. Conseguenze della mancata comunicazione dei Dati Personali</h6>
    <p>
    La comunicazione dei dati personali è necessaria al fine di poter
    utilizzare il servizio offerto dal Titolare.
    </p>
    <h6>6. Comunicazione e diffusione dei dati</h6>
    <p>
    I dati potranno essere comunicati solo all'interessato, alla Società
    con la quale il Titolare ha stipulato il contratto di utilizzo della
    web-app di proprietà del Titolare; per adempiere ad un obbligo
    giuridico al quale è soggetto il Titolare del trattamento, oppure in
    quanto sia necessario per l'esecuzione di un compito di interesse
    pubblico di cui è investito il Titolare del trattamento. Nei limiti
    pertinenti alle finalità di trattamento sopra indicate, i Suoi dati
    potranno essere comunicati a:
    </p>
    <ul>
    <li>
        la Società che ha stipulato il contratto di utilizzo della web-app
        di proprietà del Titolare;
    </li>
    <li>
        dipendenti o collaboratori del Titolare qualificati come
        “incaricati”;
    </li>
    <li>
        autorità giudiziarie o amministrative, per l’adempimento degli
        obblighi di legge.
    </li>
    </ul>
    <p>
    I dati non saranno oggetto di diffusione. I dati non verranno
    trasferiti ad un paese terzo o ad un'organizzazione internazionale.
    </p>
    <h6>7. Profilazione</h6>
    <p>
    I Suoi dati personali saranno soggetti a profilazione, solo previo Suo
    espresso consenso. La profilazione è ottenuta attraverso l’utilizzo da
    parte dell’Utente della applicazione di proprietà del Titolare.
    </p>
    <h6>8. Modalità del trattamento</h6>
    <p>
    Il trattamento dei dati personali consiste nella raccolta,
    registrazione, organizzazione, strutturazione, conservazione,
    adattamento o modifica, estrazione, consultazione, uso, comunicazione,
    diffusione, raffronto o interconnessione, limitazione, cancellazione o
    distruzione dei medesimi dati.
    </p>
    <p>
    Il trattamento dei dati personali è effettuato per le finalità di cui
    sopra, in conformità a quanto stabilito dall'articolo 5 del RGPD, su:
    </p>
    <ul>
    <li>
        supporto informatico (ivi compresi dispositivi portatili) e con
        mezzi telematici (i.e. email); nel rispetto di quanto previsto
        dall’art. 32 del RGDP in materia di misure di sicurezza ed in
        ottemperanza a quanto previsto dall’art. 29 RGDP.
    </li>
    </ul>
    <h6>9. Conservazione dei dati</h6>
    <p>
    I Suoi dati verranno archiviati in “cloud”, su server siti all’interno
    dell’Unione Europea e verranno conservati per il tempo necessario a
    conseguire le finalità indicate.
    </p>
    <p>
    I Suoi dati saranno conservati per il periodo di durata del contratto
    stipulato ed avente ad oggetto l’utilizzo della web-app di proprietà
    del Titolare e, successivamente, conservati per ulteriori 30 giorni
    decorrenti dalla scadenza del suddetto contratto. Decorso il suddetto
    termine, i Suoi dati personali saranno cancellati.&#32;
    </p>
    <h6>10. Diritti dell'interessato</h6>
    <ul>
    <li>di chiedere al titolare del trattamento l'accesso ai dati personali</li>
    <li>alla rettifica dei dati inesatti o alla integrazione di quelli incompleti</li>
    <li>alla eventuale cancellazione dei Tuoi dati personali che Ti riguardano (al verificarsi di una delle condizioni indicate nell'art. 17, paragrafo 1, RGPD, e nel rispetto delle eccezioni previste nel paragrafo 3 dello stesso articolo), fermo restando quanto previsto al punto 9 della presente informativa;</li>
    <li>alla eventuale limitazione del trattamento dei Tuoi dati personali;</li>
    <li>se il trattamento è effettuato con mezzi automatizzati (informatici) e nella misura in cui sia tecnicamente possibile, a ricevere in un formato strutturato o di trasmettere a Lui o a terzi da Lui indicati le informazioni che lo riguardano,</li>
    <li>a revocare il proprio consenso in qualsiasi momento (senza che sia pregiudicata la liceità del trattamento basata sul consenso prima della revoca), ovviamente ciò per i trattamenti effettuati sulla base di tale presupposto</li>
    <li>se il riscontro alla sue richieste non è soddisfacente o in caso lo ritenga necessario, di proporre reclamo al Garante per la Protezione dei Dati Personali (http://www.garanteprivacy.it/) - Piazza Venezia  n. 11 00186 ROMA - Centralino telefonico: (+39) 06.696771 - E-mail: garante@gpdp.it  - posta certificata protocollo@pec.gpdp.it</li>
    </ul>
    <p>
    Per far valere i propri diritti l’interessato potrà rivolgersi direttamente al Titolare per tramite dei recapiti sopra indicati tenendo presente che non sarà possibile rispondere a richieste pervenute telefonicamente ove non vi sia certezza circa l’identità del richiedente.
    </p>
`,
  text_nominale: `
    <p>
    PlayYourJob è una piattaforma di Laborplay S.r.l., P.Iva 06563810487
    con sede legale in: Via Madonna del Piano n. 6, Sesto Fiorentino (FI)
    email:&#32;
    <a href="mailto:privacy@laborplay.com">privacy@laborplay.com</a> ;
    pec:&#32;
    <a href="mailto:laborplay@legalmail.it">laborplay@legalmail.it</a>.
    </p>
    <h5>INFORMATIVA SUL TRATTAMENTO DEI DATI PERSONALI</h5>
    <h6>1) COSA SI INTENDE PER TRATTAMENTO DEI DATI PERSONALI?</h6>
    <p>
    Si intende la raccolta, registrazione, organizzazione,
    strutturazione, conservazione, adattamento o modifica,
    estrazione, consultazione, uso, comunicazione, diffusione,
    raffronto o interconnessione, limitazione, cancellazione o
    distruzione dei medesimi dati.
    </p>
    <h6>2) CHI TRATTA I MIEI DATI PERSONALI?</h6>
    <p>
    Il Titolare del Trattamento, che è Laborplay S.r.l., con sede
    legale in Via Madonna del Piano n. 6, Sesto Fiorentino (FI), P.
    Iva 06563810487 email: privacy@laborplay.com; pec:
    laborplay@legalmail.it
    </p>
    <h6>3) PER QUALI FINALITÀ SONO TRATTATI I MIEI DATI?</h6>
    <p>
    Per eseguire il servizio offerto dal Titolare; per inviarTi
    comunicazioni inerenti il servizio medesimo; per adempiere agli
    obblighi di legge ed ottemperare alle richieste provenienti
    dalle Autorità.
    </p>
    <h6>
    4) QUALI SONO LE BASI LEGALI DEL TRATTAMENTO DEI MIEI DATI?
    </h6>
    <p>
    Trattiamo i Tuoi dati in modo lecito ogni qual volta il
    trattamento è necessario ad eseguire il contratto di cui sei
    parte o all’esecuzione di misure precontrattuali adottate su Tua
    richiesta; si basa sul Tuo consenso espresso, laddove richiesto;
    è necessario per adempiere ad un obbligo legale al quale il
    Titolare è soggetto; è necessario per il perseguimento del
    legittimo interesse del Titolare. Gli interessi legittimi del
    Titolare comprendono la sua protezione, quella degli interessati
    o di altri soggetti da minacce alla sicurezza o frodi, nel
    rispetto della normativa vigente; la gestione ed il
    miglioramento dell’azienda.
    </p>
    <h6>5) QUALI DATI PERSONALI VENGONO TRATTATI?</h6>
    <ul>
    <li>Dati comuni forniti dall’interessato o da soggetti da questi autorizzati-delegati quali, a titolo esemplificativo, il genere e l’anno di nascita.</li>
    <li>Dati comuni inseriti durante la compilazione del test.</li>
    <li>Valutazioni e profili generati internamente da Laborplay®.</li>
    </ul>
    <p>
    Verranno trattati dati di minori previo consenso informato ed espresso di coloro che esercitano la patria potestà.
    </p>
    <p>
    Il Titolare del trattamento non fa utilizzo di cookies e pertanto non verranno trattati dati provenienti dall’utilizzo degli stessi.
    </p>
    <h6>6) COSA SUCCEDE SE NON FORNISCO I MIEI DATI PERSONALI? </h6>
    <p>
    La mancata comunicazione dei Tuoi dati non Ti permetterà di
    utilizzare il servizio offerto dal Titolare.
    </p>
    <h6>7) A CHI VERRANNO COMUNICATI I MIEI DATI?</h6>
    <p>
    I Tuoi dati potranno essere comunicati, nei limiti pertinenti
    alle finalità sopra indicate:
    </p>
    <p>
    a Te medesimo; alla Società con la quale il Titolare ha
    stipulato il contratto di utilizzo della web-app di proprietà
    del Titolare; per adempiere ad un obbligo giuridico al quale è
    soggetto il Titolare del trattamento, oppure in quanto sia
    necessario per l'esecuzione di un compito di interesse pubblico
    di cui è investito il Titolare del trattamento.
    </p>
    <p>
    Nei limiti pertinenti alle finalità di trattamento sopra
    indicate, i Tuoi dati potranno essere comunicati a:
    </p>
    <ul>
    <li>
        la Società che ha stipulato il contratto di utilizzo della
        web-app di proprietà del Titolare;
    </li>
    <li>
        dipendenti o collaboratori del Titolare qualificati come
        “incaricati”;
    </li>
    <li>
        &#32;
        autorità giudiziarie o amministrative, per l’adempimento degli
        obblighi di legge
    </li>
    <li>
        soggetti opportunamente designati “Responsabili del
        trattamento” dal Titolare del trattamento o che eroghino
        prestazioni funzionali ai fini sopra indicati;
    </li>
    <li>
        soggetti che elaborano i dati in esecuzione di specifici
        obblighi di legge;
    </li>
    </ul>
    <p>I Tuoi dati NON saranno oggetto di diffusione.</p>
    <p>
    I Tuoi dati NON verranno trasferiti ad un paese non appartenente
    all’Unione Europea o ad un'organizzazione internazionale;&#32;
    </p>
    <h6>8) COME VENGONO TRATTATI I MIEI DATI? VENGONO PROFILATI?</h6>
    <p>
    Il trattamento dei Tuoi dati personali viene effettuato su
    supporto informatico (ivi compresi dispositivi portatili) nel
    rispetto di quanto previsto dalla normativa vigente.
    </p>
    <p>
    I tuoi dati personali saranno soggetti a profilazione, solo
    previo Tuo espresso consenso. La profilazione è ottenuta
    attraverso l’utilizzo da parte dell’Utente della applicazione di
    proprietà del Titolare.
    </p>
    <h6>9) PER QUANTO TEMPO VERRANNO CONSERVATI I MIEI DATI? </h6>
    <p>
    I Tuoi dati verranno archiviati in “cloud”, su server siti
    all’interno dell’Unione Europea e verranno conservati per il
    tempo necessario a conseguire le finalità indicate.
    </p>
    <p>
    I Tuoi dati saranno conservati per il periodo di durata del
    contratto stipulato con la Società per l’utilizzo della web-app
    di proprietà del Titolare e, successivamente, conservati per
    ulteriori 30 giorni decorrenti dalla scadenza del suddetto
    contratto. Decorso il suddetto termine, i Tuoi dati saranno
    cancellati.
    </p>
    <h6>
    10) QUALI SONO I MIEI DIRITTI PREVISTI DALLA NORMATIVA PRIVACY
    VIGENTE?
    </h6>
    <p>Hai diritto a: </p>
    <ul>
    <li>di chiedere al titolare del trattamento l'accesso ai dati personali</li>
    <li>alla rettifica dei dati inesatti o alla integrazione di quelli incompleti</li>
    <li>alla eventuale cancellazione dei Tuoi dati personali che Ti riguardano (al verificarsi di una delle condizioni indicate nell'art. 17, paragrafo 1, RGPD, e nel rispetto delle eccezioni previste nel paragrafo 3 dello stesso articolo), fermo restando quanto previsto al punto 9 della presente informativa;</li>
    <li>alla eventuale limitazione del trattamento dei Tuoi dati personali;</li>
    <li>se il trattamento è effettuato con mezzi automatizzati (informatici) e nella misura in cui sia tecnicamente possibile, a ricevere in un formato strutturato o di trasmettere a Lui o a terzi da Lui indicati le informazioni che lo riguardano,</li>
    <li>a revocare il proprio consenso in qualsiasi momento (senza che sia pregiudicata la liceità del trattamento basata sul consenso prima della revoca), ovviamente ciò per i trattamenti effettuati sulla base di tale presupposto</li>
    <li>se il riscontro alla sue richieste non è soddisfacente o in caso lo ritenga necessario, di proporre reclamo al Garante per la Protezione dei Dati Personali (http://www.garanteprivacy.it/) - Piazza Venezia  n. 11 00186 ROMA - Centralino telefonico: (+39) 06.696771 - E-mail: garante@gpdp.it  - posta certificata protocollo@pec.gpdp.it</li>
    </ul>
    <p>
    Per far valere i propri diritti l’interessato potrà rivolgersi direttamente al Titolare per tramite dei recapiti sopra indicati tenendo presente che non sarà possibile rispondere a richieste pervenute telefonicamente ove non vi sia certezza circa l’identità del richiedente.
    </p>
`,
  text_anonimo: `
    <p>
    PlayYourJob è una piattaforma di Laborplay S.r.l., P.Iva 06563810487
    con sede legale in: Via Madonna del Piano n. 6, Sesto Fiorentino (FI)
    email:&#32;
    <a href="mailto:privacy@laborplay.com">privacy@laborplay.com</a> ;
    pec:&#32;
    <a href="mailto:laborplay@legalmail.it">laborplay@legalmail.it</a>.
    </p>
    <p>
    PlayYourJob <b>non raccoglie informazioni e dati personali</b>&#32;
    ovvero che consentono di risalire alla persona che le ha fornite.
    L’accesso alla piattaforma avviene attraverso l’utilizzo di Token
    anonimi e di credenziali di accesso generate automaticamente dal
    sistema per conto di un’Azienda che richiede il servizio. Le
    informazioni inserite nella piattaforma PlayYourJob (genere, età,
    preferenze di gioco) sono generiche e non consentono di risalire
    all’identità del rispondente. I dati forniti potranno essere
    trattati unicamente per le seguenti finalità: esecuzione del
    servizio PlayYourJob con particolare riferimento all’utilizzo di
    giochi ed alla partecipazione al questionario profilativo onde
    realizzare la profilazione automatica job/match per conto
    dell’Azienda che richiede il servizio. L’Azienda che richiede il
    servizio è responsabile dell’abbinamento dei Token agli utenti ed
    è l’unica a conoscerne le informazioni personali (nome, cognome,
    e-mail, etc.). I dati e le informazioni dell’utente sono elaborati
    da Laborplay e restituiti in forma anonima relativa al singolo
    Token all’Azienda che ha richiesto il servizio, salvo nel caso in
    cui Laborplay sia stata espressamente nominata responsabile del
    trattamento ai sensi di legge. I cookies utilizzati servono a
    ottimizzare l’esperienza utente e facilitarne la navigazione tra
    le pagine. I dati e le risposte fornite sono conservati a norma di
    legge su server AWS Amazon siti all’interno dell’U.E. per conto di
    Laborplay.
    </p>
`,
};
