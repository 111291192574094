import React from "react";
import { useTranslation } from "react-i18next";
import itFlag from "./../../img/lang/it.png";
import enFlag from "./../../img/lang/en.png";
import esFlag from "./../../img/lang/es.png";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const flags = {
  it: itFlag,
  en: enFlag,
  es: esFlag,
};

export default function LanguageSelector() {
  const { i18n } = useTranslation();
  const { language } = i18n;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {Object.entries(flags).map(([lang, flag]) =>
        language.includes(lang) ? (
          <Link to="./language" key={lang}>
            <img
              src={flag}
              alt={lang}
              title={lang.toUpperCase()}
              style={{
                boxSizing: "border-box",
                width: "2.6rem",
                height: "2.6rem",
                border: "2px solid white",
                borderRadius: "50%",
              }}
            />
          </Link>
        ) : null
      )}
    </div>
  );
}

export function ChangeLanguage({ close }) {
  const { i18n } = useTranslation();
  const { language } = i18n;
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        justifyContent: "space-evenly",
        /* gridTemplateColumns: "repeat(auto-fill, 1rem)",
        gap: "2rem", */
      }}
    >
      {Object.entries(flags).map(([lang, flag]) => (
        <img
          src={flag}
          alt={lang}
          key={lang}
          title={lang.toUpperCase()}
          style={{
            boxSizing: "border-box",
            width: "4.6rem",
            height: "4.6rem",
            border: lang === language ? "2px solid white" : "none",
            borderRadius: "50%",
            cursor: "pointer",
            margin: "1rem",
          }}
          onClick={() => {
            close();
            i18n.changeLanguage(lang);
          }}
        />
      ))}
    </div>
  );
}

export function useGetLanguage() {
  const { i18n } = useTranslation();
  const { language } = i18n;
  const detected = language.split("-")[0];
  if (!Object.keys(flags).includes(detected)) {
    return "en";
  } else {
    return detected;
  }
}

export function useNamespacedTranslation() {
  const t = useTranslation().t;

  const versione_testi = useSelector(
    (state) => state.userData?.[0]?.versione_testi
  );

  const namespace = versione_testi ? versione_testi + "." : "";



  return {
    t: function (sel) {
      console.log(namespace, sel);
      return t(namespace + sel);
    },
  };
}
