export default {
  Powered: `
    <tr>
        <td>Iniciativa</td>
        <td>
            Predisposición a actuar de forma autónoma, sin ningún estímulo    
            externo concreto, también con el fin de optimizar el trabajo o  
            evitar/anticiparse a los problemas.
        </td>
    </tr>
    <tr>
        <td>Energía</td>
        <td>
            Mostrar pasión y dinamismo en las actividades realizadas, 
            comprometiéndose con constancia en las tareas asignadas y
            transmitiendo entusiasmo a los que lo rodean.
        </td>
    </tr>
    <tr>
        <td>Toma de decisiones</td>
        <td>
            Tendencia a actuar con decisión incluso en contextos de ambigüedad 
            o falta de información definida, escapando de situaciones sin 
            salida.
        </td>
    </tr>
    <tr>
        <td>Objetivo</td>
        <td>
            Buscar resultados apreciables, centrar las energías en la 
            superación de las dificultades crecientes teniendo en cuenta las 
            normas compartidas.
        </td>
    </tr>
    <tr>
        <td>Asunción de riesgos</td>
        <td>
            Iniciar rápidamente nuevas actividades, incluso en condiciones de 
            falta de información, asumiendo riesgos basados en la experiencia 
            y la intuición, pero manteniendo la responsabilidad de los 
            resultados.
        </td>
    </tr>
    <tr>
        <td>Perseverancia</td>
        <td>
           Capacidad para poner en práctica comportamientos orientados a 
           mantener el compromiso con la consecución de los objetivos 
           personales y grupales, sin retroceder ante las dificultades.
        </td>
    </tr>
    `,
  Leader: `
      <tr>
        <td>Habilidad para involucrar</td>
        <td>
          Capacidad para generar seguidores, para canalizar la energía de uno 
          mismo y de sus colaboradores hacia una visión concreta, superando 
          cualquier momento de dificultad.
        </td>
      </tr>
      <tr>
        <td>Comunicación eficaz</td>
        <td>
          La capacidad de explicar y describir algo o convencer a los demás 
          mediante una presentación o discurso, compartiendo la información de 
          forma eficaz y utilizando también los canales no verbales de forma 
          efectiva.
        </td>
      </tr>
      <tr>
        <td>Comunicación persuasiva</td>
        <td>
          Habilidades de comunicación destinadas a convencer a otros de la 
          corrección y positividad de las propias posiciones y opiniones.
        </td>
      </tr>
      <tr>
        <td>Habilidad para motivar</td>
        <td>
          Capacidad para orientar el comportamiento de los demás con el fin de 
          lograr un objetivo común.
        </td>
      </tr>
      <tr>
        <td>Inteligencia emocional</td>
        <td>
          Capacidad para reconocer, utilizar, comprender y gestionar 
          conscientemente las emociones propias y ajenas.
        </td>
      </tr>
      <tr>
        <td>Visión estratégica</td>
        <td>
          Capacidad para definir objetivos e indicar los medios, herramientas 
          y acciones para alcanzarlos en una perspectiva a medio/largo plazo.
        </td>
      </tr>
    `,
  Accurate: `
      <tr>
        <td>Análisis y síntesis</td>
        <td>
          Capacidad para leer y descodificar la información dada de forma 
          escrita y/o verbal, buscando activamente elementos útiles para 
          profundizar y desglosar los problemas tratados.
        </td>
      </tr>
      <tr>
        <td>Precisión</td>
        <td>
          Inclinación a producir resultados de calidad en términos de detalle 
          y precisión, prestando atención a los detalles y no solo realizando 
          la tarea asignada.
        </td>
      </tr>
      <tr>
        <td>Resolución de problemas</td>
        <td>
          La capacidad de brindar posibles alternativas de solución a un 
          problema, aportando soluciones concretas que optimicen las 
          actividades y los flujos de trabajo en respuesta a las limitaciones 
          existentes.
        </td>
      </tr>
      <tr>
        <td>Flexibilidad</td>
        <td>
          Ser capaz de adaptarse a diferentes situaciones y cambios de 
          contexto, mostrando una aptitud de confrontación con ideas 
          diferentes a las propias y una disposición a reorganizar su 
          pensamiento sobre la base de nueva información.
        </td>
      </tr>
      <tr>
        <td>Organización</td>
        <td>
          Gestionar las diferentes actividades de trabajo planificando y 
          coordinando procesos complejos, para optimizar el uso de los 
          recursos disponibles.
        </td>
      </tr>
      <tr>
        <td>Creatividad</td>
        <td>
          Tendencia a formular muchas respuestas a un mismo problema y a idear 
          soluciones originales, que rompan el molde y se alejen de los
          convencionalismos.
        </td>
      </tr>
    `,
  Easy: `
      <tr>
        <td>Colaboración</td>
        <td>
          Trabajar eficazmente con colegas y en equipo, con clientes 
          internos/externos, integrando diferentes funciones para alcanzar 
          objetivos comunes.
        </td>
      </tr>
      <tr>
        <td>Soporte</td>
        <td>
          Capacidad de prestar atención activamente, de comprender las 
          necesidades de los compañeros y de apoyarlos en cualquier 
          circunstancia.
        </td>
      </tr>
      <tr>
        <td>Resiliencia</td>
        <td>
          Resistir, hacer frente y reorganizar positivamente la propia vida 
          tras experimentar un acontecimiento negativo o un riesgo, 
          aprovechando las oportunidades incluso en situaciones negativas como 
          gestor del cambio.
        </td>
      </tr>
      <tr>
        <td>Orientación al cliente</td>
        <td>
          Identificar y satisfacer las necesidades del cliente (tanto internas 
          como externas) para construir, gestionar y desarrollar una relación 
          de fidelidad a largo plazo.
        </td>
      </tr>
      <tr>
        <td>Competencia relacional </td>
        <td>
          Capacidad de relacionarse con terceros, capacidad de captar la 
          posición del interlocutor para entablar una relación lo más 
          equilibrada posible.
        </td>
      </tr>
      <tr>
        <td>Integridad</td>
        <td>
          Actuar de conformidad con las normas de un contexto específico, 
          respetando los principios éticos y las obligaciones morales, 
          mostrando una actitud caracterizada por la apertura, la comprensión 
          y la preocupación por los demás.
        </td>
      </tr>
    `,
};
