export default {
  Powered: `
    <tr>
        <td>Initiative</td>
        <td>
            A predisposition to act autonomously, without particular external 
            stimuli, also in order to optimise work or to avoid/anticipate 
            problems.
        </td>
    </tr>
    <tr>
        <td>Energy</td>
        <td>
            Show passion and a dynamic approach to your work, constantly 
            engaging in the assigned tasks and transmitting enthusiasm to the 
            people around you.
        </td>
    </tr>
    <tr>
        <td>Decision-Making</td>
        <td>
            Tendency to work resolutely even in ambiguous contexts or in the 
            absence of defined information, avoiding dead ends.
        </td>
    </tr>
    <tr>
        <td>Objective</td>
        <td>
            Aspiring for appreciable results, by focusing one's energies on 
            overcoming increasing difficulties, taking shared standards into 
            consideration.
        </td>
    </tr>
    <tr>
        <td>Risk Taking</td>
        <td>
            Quickly initiating new activities, even when information is lacking, 
            taking risks based on experience and intuition, while retaining 
            responsibility for the results.
        </td>
    </tr>
    <tr>
        <td>Perseverance</td>
        <td>
            Ability to implement behaviours aimed at maintaining the commitment 
            to achieve personal and group goals, not giving up in the face of 
            difficulties.        
        </td>
    </tr>
    `,
  Leader: `
      <tr>
        <td>Ability to engage</td>
        <td>
          Ability to generate a following, to channel your own energy and that 
          of your co-workers towards a specific vision, while overcoming any 
          difficult moments.
        </td>
      </tr>
      <tr>
        <td>Effective communication</td>
        <td>
          The ability to explain and describe something or convince others 
          through a presentation or a speech, share information effectively and 
          use non-verbal channels effectively..
        </td>
      </tr>
      <tr>
        <td>Persuasion</td>
        <td>
          The communicative ability to convince others of the correctness and 
          positivity of your own stances and opinions.
        </td>
      </tr>
      <tr>
        <td>Ability to motivate</td>
        <td>
          Ability to direct the behaviour of others in order to achieve a common 
          goal.
        </td>
      </tr>
      <tr>
        <td> Emotional intelligence</td>
        <td>
          Ability to recognise, use, understand and consciously manage your own 
          emotions and those of others.
        </td>
      </tr>
      <tr>
        <td> Strategic vision</td>
        <td>
          Ability to define objectives and indicate the means, tools and actions 
          to achieve them in a medium/long term perspective.
        </td>
      </tr>
    `,
  Accurate: `
      <tr>
        <td>Analysis and summary</td>
        <td>
          The ability to read and decode information given in written and/or 
          verbal format, actively searching for useful information to scrutinise 
          and break down the problems faced.
        </td>
      </tr>
      <tr>
        <td>Care</td>
        <td>
          The inclination to produce quality results in terms of detail and 
          precision, taking care of the details and not just performing the 
          assigned task.
        </td>
      </tr>
      <tr>
        <td>Problem-solving</td>
        <td>
          The ability to provide possible alternative solutions to a problem, 
          providing concrete solutions that optimise activities and workflows in 
          response to existing constraints.
        </td>
      </tr>
      <tr>
        <td>Flexibility</td>
        <td>
          The ability to adapt to different situations and context changes, 
          showing openness to ideas other than your own and willingness to 
          reorganise your thinking based on new information.
        </td>
      </tr>
      <tr>
        <td>Organisation</td>
        <td>
          Managing the various work activities by planning and coordinating 
          linked processes, in such a way as to optimise the use of available 
          resources.
        </td>
      </tr>
      <tr>
        <td>Creativity</td>
        <td>
          Tendency to formulate many answers to the same problem and to conceive 
          original solutions, breaking the mould and flouting conventions.
        </td>
      </tr>
    `,
  Easy: `
      <tr>
        <td>Cooperation</td>
        <td>
          Work efficiently with colleagues and groups, with internal/external 
          customers, integrating different roles to achieve common goals.
        </td>
      </tr>
      <tr>
        <td>Support</td>
        <td>
          Ability to listen actively, to understand the needs of colleagues and 
          to support them in all circumstances.
        </td>
      </tr>
      <tr>
        <td>Resilience</td>
        <td>
          Resists, faces, and positively reorganises one's life after suffering 
          a negative event or risk, seizing opportunities even in negative    
          situations in their capacity to manage change.
        </td>
      </tr>
      <tr>
        <td>Customer orientation</td>
        <td>
          Identify and satisfy customer needs (both internal and external) to 
          build, manage and develop a long-term loyalty relationship.
        </td>
      </tr>
      <tr>
        <td>Social skills</td>
        <td>
          Ability to relate to third parties, ability to grasp the position of 
          the speaker in order to form relationships that are as balanced as 
          possible.
        </td>
      </tr>
      <tr>
        <td>Integrity</td>
        <td>
          Acting in compliance with the rules of a specific context, adhering to 
          ethical principles and moral obligations, showing an attitude 
          characterised by sincerity, understanding and interest in others.
        </td>
      </tr>
    `,
};
