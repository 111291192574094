import React, { memo, useMemo, useCallback } from 'react';
import { Link } from 'react-router-dom';

export const NewChart = memo(props => {

    // clip path to crop graph according to data
	const [Pscale,Lscale,Ascale,Yscale] = useMemo(()=>{
        const {P,L,A,Y} = props;
        return Object.values({P,L,A,Y}).map(v => 1 + 1.9 * (v-1) / 6);
	}, [props]);

	// pad graph values
    const pad = useCallback(s => s.toString().padEnd(3,'.0'), []);
    
    return (
        <svg id="newChart" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 314.8 359.4" y="0px" x="0px" version="1.1">
            <filter id="dropshadow" height="130%">
                <feGaussianBlur in="SourceAlpha" stdDeviation="2.8"/>
                <feOffset dx="2" dy="2" result="offsetblur"/>
                <feComponentTransfer>
                    <feFuncA type="linear" slope="0.3"/>
                </feComponentTransfer>
                <feMerge> 
                    <feMergeNode/> 
                    <feMergeNode in="SourceGraphic"/>
                </feMerge>
            </filter>
            <style id="style2" type="text/css" dangerouslySetInnerHTML={{__html: ".st0{fill:#F39200;} .st1{fill:#11496E;} .st2{fill:#006633;} .st3{fill:#9A1915;}" }} />
            <Link to="powered">
                <path id="path10" d="M41.4,45.6L41.5,0l9.8,0c1.6,0,3.2,0.2,4.7,0.7c1.4,0.5,2.7,1.4,3.7,2.5c1.1,1.2,1.9,2.6,2.3,4.2  c0.5,2.1,0.7,4.3,0.6,6.5c0,1.7-0.1,3.5-0.4,5.2c-0.2,1.4-0.8,2.8-1.5,4c-0.9,1.5-2.1,2.7-3.6,3.5c-1.8,0.9-3.8,1.3-5.9,1.2l-3.3,0  l0,17.8L41.4,45.6z M48,6.2l0,15.5l3.1,0c1.1,0.1,2.1-0.1,3.1-0.6c0.7-0.3,1.3-0.9,1.6-1.6c0.4-0.7,0.5-1.6,0.6-2.4  c0.1-0.9,0.1-2,0.1-3.1s0-2.1-0.1-3c0-0.9-0.2-1.7-0.6-2.5c-0.3-0.7-0.9-1.3-1.5-1.7c-0.9-0.4-1.9-0.6-2.9-0.6L48,6.2z" className="st3" />
            </Link>
            <Link to="leader">
                <path id="path4" d="M295.3,107.3l0.1-45.6l6.5,0l-0.1,39.1l12.9,0l0,6.5L295.3,107.3z" className="st0" />
            </Link>
            <Link to="accurate">
                <path id="path8" d="M0,299.5L10.3,254l5.4,0l10.1,45.6l-6.5,0l-1.9-9.8l-8.9-0.1l-1.9,9.8L0,299.5z M16.1,283.6L13,267l-0.1,0  l-3.2,16.5L16.1,283.6z" className="st2" />
            </Link>
            <Link to="easy">
                <path id="path6" d="M263.8,359.3l0-19.2l-8.9-26.3l6.9,0l5.2,18.1l0.1,0l5.3-18.1l6.9,0l-9,26.3l0,19.3L263.8,359.3z" className="st1" />
            </Link>
            <path id="path12" d="M141.3,298.6l-87.2-0.1c-8.1,0-14.7-6.6-14.6-14.7c0,0,0,0,0,0l0.1-87.2c0-8.1,6.6-14.7,14.7-14.7c0,0,0,0,0,0  l101.8,0.1l0,0L156,283.9C156,292,149.4,298.6,141.3,298.6L141.3,298.6z" className="st2" />
            <path id="path14" d="M39.1,159.3l0.1-87.2c0-8.1,6.6-14.7,14.7-14.6c0,0,0,0,0,0l87.2,0.1c8.1,0,14.7,6.6,14.6,14.7v0L155.6,174l0,0  l-101.9-0.1C45.7,174,39.1,167.4,39.1,159.3C39.1,159.3,39.1,159.3,39.1,159.3z" className="st3" />
            <path id="path16" d="M280.4,196.8l-0.1,87.2c0,8.1-6.5,14.6-14.6,14.6l-87.2-0.1c-8.1,0-14.7-6.6-14.6-14.7l0,0l0.1-101.9l0,0  l101.9,0.1C273.9,182.1,280.5,188.7,280.4,196.8C280.5,196.8,280.5,196.8,280.4,196.8L280.4,196.8z" className="st1" />
            <path id="path18" d="M178.8,57.4l87.2,0.1c8.1,0,14.7,6.6,14.6,14.7c0,0,0,0,0,0l-0.1,87.2c0,8.1-6.6,14.7-14.7,14.6c0,0,0,0,0,0  l-101.9-0.1l0,0l0.1-101.9C164.2,64,170.7,57.4,178.8,57.4L178.8,57.4L178.8,57.4z" className="st0" />
            
            <g style={{fill: '#e6332a', fillOpacity: 1}} transform="translate(29.4,45.6)" id="Livello_2_1_-2">
                <g style={{fill: '#e6332a', fillOpacity: 1}} id="Livello_1-2-2">
                    <Link to="powered">
                        <path style={{fill: '#e6332a', fillOpacity: 1, strokeWidth: '0.349235', transformOrigin: "38.9% 34.7%", transform: `scale(${Pscale})` }} className="st0" d="m 86.089093,84.028433 31.955007,0.03492 c 2.30495,0 4.19082,1.88587 4.19082,4.190821 L 122.2,124.4 v 0 l -36.145829,-0.0349 c -2.304951,0 -4.190821,-1.88587 -4.190821,-4.19082 l 0.03492,-31.955006 c 0,-2.304952 1.850946,-4.190821 4.19082,-4.190821 z" id="path4-7" />
                    </Link>
                </g>
            </g>
            
            <g style={{fill: '#ffce00', fillOpacity: 1}} transform="matrix(0.34923504,0,0,0.34923504,167.9,129.56335)" id="Livello_2_1_-5">
                <g style={{fill: '#ffce00', fillOpacity: 1}} id="Livello_1-2-9">
                    <Link to="leader">
                        <path style={{fill: '#ffce00', fillOpacity: 1, transformOrigin: "0% 32.2%", transform: `scale(${Lscale})`}} className="st0" d="m 12.1,0 91.5,0.1 c 6.6,0 12,5.4 12,12 l -0.1,91.5 c 0,6.6 -5.4,12 -12,12 L 0,115.5 v 0 L 0.1,12 C 0.1,5.4 5.5,0 12.1,0 Z" id="path4-1" />
                    </Link>
                </g>
            </g>

            <g style={{fill: '#00983a', fillOpacity: 1}} transform="matrix(0.34923504,0,0,0.34923504,111.72843,185.96508)" id="Livello_2_1_-4">
                <g style={{fill: '#00983a', fillOpacity: 1}} id="Livello_1-2-1">
                    <Link to="accurate">
                        <path style={{fill: '#00983a', fillOpacity: 1, transformOrigin: "37% 0%", transform: `scale(${Ascale})`}} className="st0" d="M 103.5,115.6 12,115.5 c -6.6,0 -12,-5.4 -12,-12 0,0 0,0 0,0 L 0.1,12 C 0.1,5.4 5.5,0 12.1,0 l 103.5,0.1 v 0 l -0.1,103.5 c 0,6.6 -5.4,12 -12,12 z" id="path4-4" />
                    </Link>
                </g>
            </g>

            <g style={{fill: '#0b74ba', fillOpacity: 1}} transform="matrix(0.34923504,0,0,0.34923504,167.96508,185.9)" id="Livello_2_1_">
                <g style={{fill: '#0b74ba', fillOpacity: 1}} id="Livello_1-2">
                    <Link to="easy">
                        <path style={{fill: '#0b74ba', fillOpacity: 1, transformOrigin: "0% 0%", transform: `scale(${Yscale})`}} className="st0" d="m 115.6,12.1 -0.1,91.5 c 0,6.6 -5.4,12 -12,12 L 12,115.5 c -6.6,0 -12,-5.4 -12,-12 L 0.1,0 v 0 l 103.5,0.1 c 6.6,0 12,5.4 12,12 0,0 0,0 0,0 z" id="path4-6" />
                    </Link>
                </g>
            </g>

            <text transform="scale(1.0710448,0.93366773)" id="text902" y="48.971672" x="50.435097" style={{fontStyle: 'normal', fontWeight: 'normal', fontSize: '37.3467px', lineHeight: '1.25', fill: '#9a1915', fillOpacity: 1, stroke: 'none', strokeWidth: '0.933668'}}><tspan style={{fontStyle: 'normal', fontVariant: 'normal', fontWeight: 'normal', fontStretch: 'normal', fontSize: '14.9387px', fill: '#9a1915', fillOpacity: 1, strokeWidth: '0.933668'}} y="48.971672" x="50.435097" id="tspan900">{pad(props.P)}</tspan></text>
            <text transform="scale(1.0710441,0.93366839)" style={{fontStyle: 'normal', fontWeight: 'normal', fontSize: '37.3467px', lineHeight: '1.25', fill: '#f39200', fillOpacity: 1, stroke: 'none', strokeWidth: '0.933668'}} x="273.86145" y="134.25009" id="text902-6"><tspan id="tspan900-6" x="273.86145" y="134.25009" style={{fontStyle: 'normal', fontVariant: 'normal', fontWeight: 'normal', fontStretch: 'normal', fontSize: '14.9387px', fill: '#f39200', fillOpacity: 1, strokeWidth: '0.933668'}}>{pad(props.L)}</tspan></text>
            <text transform="scale(1.0710441,0.93366839)" style={{fontStyle: 'normal', fontWeight: 'normal', fontSize: '37.3467px', lineHeight: '1.25', fill: '#006633', fillOpacity: 1, stroke: 'none', strokeWidth: '0.933668'}} x="1.8958176" y="264.47507" id="text902-4"><tspan id="tspan900-2" x="1.8958176" y="264.47507" style={{fontStyle: 'normal', fontVariant: 'normal', fontWeight: 'normal', fontStretch: 'normal', fontSize: '14.9387px', fill: '#006633', fillOpacity: 1, strokeWidth: '0.933668'}}>{pad(props.A)}</tspan></text>
            <text transform="scale(1.0710441,0.93366839)" style={{fontStyle: 'normal', fontWeight: 'normal', fontSize: '37.3467px', lineHeight: '1.25', fill: '#11496e', fillOpacity: 1, stroke: 'none', strokeWidth: '0.933668'}} x="213.13716" y="347.6676" id="text902-65"><tspan id="tspan900-4" x="213.13716" y="347.6676" style={{fontStyle: 'normal', fontVariant: 'normal', fontWeight: 'normal', fontStretch: 'normal', fontSize: '14.9387px', fill: '#11496e', fillOpacity: 1, strokeWidth: '0.933668'}}>{pad(props.Y)}</tspan></text>
            
        </svg>
    );
});