export default {
  Powered: `
    <tr>
        <td>Iniziativa</td>
        <td>
            Predisposizione ad agire autonomamente, senza particolari stimoli
            esterni anche allo scopo di ottimizzare il lavoro o di
            evitare/anticipare i problemi.
        </td>
    </tr>
    <tr>
        <td>Energia</td>
        <td>
            Mostrare passione e dinamismo nelle attività svolte, impegnandosi con
            costanza nei compiti assegnati e trasmettendo entusiasmo alle persone
            circostanti.
        </td>
    </tr>
    <tr>
        <td>Decision Making</td>
        <td>
            Tendenza ad operare in modo risoluto anche nei contesti di ambiguità o
            in mancanza di informazioni definite, fuggendo dalle situazioni di
            impasse.
        </td>
    </tr>
    <tr>
        <td>Obiettivo</td>
        <td>
            Ambire a risultati apprezzabili, focalizzando le proprie energie per
            il superamento di difficoltà crescenti tenendo in considerazione gli
            standard condivisi.
        </td>
    </tr>
    <tr>
        <td>Assunzione del rischio</td>
        <td>
            Avviare velocemente nuove attività, anche in condizioni carenti di
            informazioni, assumendosi rischi basati sull’esperienza e
            sull’intuito, conservando comunque la responsabilità sui risultati.
        </td>
    </tr>
    <tr>
        <td>Perseveranza</td>
        <td>
            Capacità di mettere in atto comportamenti orientati al mantenimento
            dell’impegno per conseguire gli obiettivi personali e di gruppo, non
            arretrando di fronte alle difficoltà.
        </td>
    </tr>
    `,
  Leader: `
      <tr>
        <td>Capacità di coinvolgere</td>
        <td>
          Capacità di generare un seguito, di incanalare l’energia propria e dei
          suoi collaboratori verso una determinata vision, superando eventuali
          momenti di difficoltà.
        </td>
      </tr>
      <tr>
        <td>Comunicazione efficace</td>
        <td>
          La capacità di spiegare e descrivere qualcosa oppure convincere altri
          attraverso una presentazione o un discorso, condividendo efficacemente
          le informazioni e utilizzando efficacemente anche i canali non
          verbali.
        </td>
      </tr>
      <tr>
        <td>Persuasione</td>
        <td>
          La capacità comunicativa rivolta a convincere gli altri della
          correttezza e positività delle proprie posizioni e delle proprie
          opinioni.
        </td>
      </tr>
      <tr>
        <td>Capacità di motivare</td>
        <td>
          Capacità di orientare i comportamenti altrui al fine di realizzare un
          obiettivo comune.
        </td>
      </tr>
      <tr>
        <td>Intelligenza emotiva</td>
        <td>
          Capacità di riconoscere, utilizzare, comprendere e gestire in modo
          consapevole le proprie e le altrui emozioni.
        </td>
      </tr>
      <tr>
        <td>Visione strategica</td>
        <td>
          Capacità di definire obiettivi e di indicare i mezzi, gli strumenti e
          le azioni per raggiungerli in una prospettiva di medio/lungo periodo.
        </td>
      </tr>
    `,
  Accurate: `
      <tr>
        <td>Analisi e sintesi</td>
        <td>
          Capacità di leggere e decodificare le informazioni date in forma
          scritta e/o verbale, ricercando attivamente elementi utili ad
          approfondire e scomporre i problemi affrontati.
        </td>
      </tr>
      <tr>
        <td>Accuratezza</td>
        <td>
          Inclinazione a produrre risultati di qualità in termini di dettagli e
          precisione, curando i particolari e non limitandosi alla sola
          esecuzione del compito assegnato.
        </td>
      </tr>
      <tr>
        <td>Problem solving</td>
        <td>
          La capacità di fornire possibili alternative di soluzione ad un
          problema, fornendo soluzioni concrete che ottimizzino le attività e i
          flussi di lavoro in risposta ai vincoli presenti.
        </td>
      </tr>
      <tr>
        <td>Flessibilità</td>
        <td>
          Sapersi adattare a situazioni differenti e a cambiamenti di contesto,
          mostrando attitudine al confronto con idee diverse dalla propria e
          disponibilità a riorganizzare il proprio pensiero sulla base di nuove
          informazioni.
        </td>
      </tr>
      <tr>
        <td>Organizzazione</td>
        <td>
          Gestire le diverse attività lavorative programmando e coordinando
          processi articolati, in modo tale da ottimizzare l’uso delle risorse
          disponibili.
        </td>
      </tr>
      <tr>
        <td>Creatività</td>
        <td>
          Tendenza a formulare molte risposte ad uno stesso problema e a
          concepire soluzioni originali, rompendo gli schemi e allontanando la
          convenzionalità.
        </td>
      </tr>
    `,
  Easy: `
      <tr>
        <td>Collaborazione</td>
        <td>
          Lavorare efficacemente con colleghi e in gruppo, con clienti
          interni/esterni, integrando i diversi ruoli per il raggiungimento
          degli obiettivi comuni.
        </td>
      </tr>
      <tr>
        <td>Supporto</td>
        <td>
          Capacità di ascoltare attivamente, di comprendere le esigenze dei
          colleghi e di supportarli in qualsiasi circostanza.
        </td>
      </tr>
      <tr>
        <td>Resilienza</td>
        <td>
          Resistere, fronteggiare e riorganizzare positivamente la propria vita
          dopo aver subito un evento negativo o un rischio, cogliendo
          opportunità anche nelle situazioni negative in quanto capace di
          gestire il cambiamento.
        </td>
      </tr>
      <tr>
        <td>Orientamento al cliente</td>
        <td>
          Individuare e soddisfare le esigenze del cliente (sia interno che
          esterno) per costruire, gestire e sviluppare un rapporto di
          fidelizzazione di lungo termine.
        </td>
      </tr>
      <tr>
        <td>Capacità relazionali</td>
        <td>
          Capacità di relazionarsi a terzi, abilità nel cogliere la posizione
          dell’interlocutore al fine di intraprendere rapporti il più possibile
          equilibrati.
        </td>
      </tr>
      <tr>
        <td>Integrità</td>
        <td>
          Agire nel rispetto delle regole di uno specifico contesto, attenendosi
          a principi etici e obblighi morali, mostrando un atteggiamento
          contraddistinto da franchezza, comprensione e interesse per gli altri.
        </td>
      </tr>
    `,
};
