import React, { memo, useMemo } from "react";

export const ChartRank = memo((props) => {
  // to get rank 1-9, split by 11 and approximate to top
  const rank9 = props.standard;

  // to get rank 1-5 just map the values of rank9
  const rank5 = [null, 1, 2, 2, 3, 3, 3, 4, 4, 5][rank9];

  // rankLine is a dark line over the default line, 12 is the offset from left, 8 from right and 78.9 is the size of every section
  const rankLineSize = 12 + rank9 * 78.9 + (rank9 === 9 ? 8 : 0);

  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 737.01 368.5"
      style={{
        enableBackground: "new 0 0 737.01 368.5",
      }}
    >
      <style
        dangerouslySetInnerHTML={{
          __html: `
			  .st0{fill:#FFFFFF;}
			  .st0:nth-child(${rank5}) {fill:#ca4956;}
			  .st1{fill:#FDEBEA;}
			  .st1:nth-child(-n+${rank9}) {fill:#d26c6c;}
			  .st2{fill:none;stroke:#000000;stroke-width:0.6802;stroke-miterlimit:10;}
			  .st3{fill:#FBDEDB;}
			  .rankLine{fill:#db8d87;width:${rankLineSize}px;}
			  .st4{fill:#E73351;}
			  .st4:nth-child(-n+${rank9}) {fill:white;}
			  .st5{fill:#F7BAB6;}
			  .wordRank:nth-child(-n+${rank5 - 1}) .st5 {fill:#da8c86;}
			  .wordRank:nth-child(${rank5}) .st5 {fill:black;}
			  .st6{fill:none;stroke:#000000;stroke-width:1.5;stroke-miterlimit:10;}
			  .st7{fill:none;stroke:#E73351;stroke-width:0.9069;stroke-miterlimit:10;}
			`,
        }}
      />
      <g id="Livello_2">
        <g>
          <path
            className="st0"
            d="M14.82,346.04v8.13c0,5.32,4.35,9.67,9.67,9.67h59.67c5.32,0,9.67-4.35,9.67-9.67v-8.13H14.82z"
          />
          <path
            className="st0"
            d="M93.85,346.04v4.15c0,7.51,6.14,13.65,13.65,13.65h130.14c7.51,0,13.65-6.14,13.65-13.65l0-4.15H93.85z"
          />
          <path
            className="st0"
            d="M252.19,346.04v1.12c0,9.17,7.51,16.68,16.68,16.68h201.67c9.17,0,16.68-7.51,16.68-16.68v-1.12H252.19z"
          />
          <path
            className="st0"
            d="M487.93,346.04l0,4.15c0,7.51,6.14,13.65,13.65,13.65h130.14c7.51,0,13.65-6.14,13.65-13.65v-4.15H487.93z"
          />
          <path
            className="st0"
            d="M645.31,346.04l0,8.13c0,5.32,4.35,9.67,9.67,9.67h59.67c5.32,0,9.67-4.35,9.67-9.67l0-8.13H645.31z"
          />
        </g>
        <g className="bars">
          <path
            className="st1"
            d="M87.39,327.89c-2.48-0.01-4.96-0.07-7.44-0.01c-1.12,0.03-1.53-0.25-1.52-1.45
			c0.05-6.68,0.03-13.35,0.03-20.03c0-8.16-0.02-16.32,0.03-24.47c0.01-1.23-0.37-1.54-1.57-1.54c-15.92,0.04-31.83,0.02-47.75,0.03
			c-1.6,0-1.6,0.01-1.6,1.55c0,10.92,0,21.84,0,32.75c0,0.43,0,0.86,0,1.41c0.54,0.03,0.96,0.07,1.38,0.07
			c4.04,0,8.08,0.03,12.12-0.02c0.92-0.01,1.29,0.21,1.26,1.18c-0.06,2.16-0.05,4.32,0,6.48c0.02,0.97-0.37,1.23-1.28,1.22
			c-7.04-0.02-14.08-0.02-21.12,0c-0.87,0-1.18-0.26-1.18-1.17c0.02-15.4,0.01-30.79,0.03-46.19c0-3.07,1.7-5.15,4.71-5.97
			c0.19-0.05,0.35-0.17,0.53-0.26c19.36,0,38.72,0,58.08,0c1.04,0.54,2.15,0.99,3.1,1.66c1.39,0.97,1.76,2.57,2.18,4.1
			C87.39,294.13,87.39,311.01,87.39,327.89z"
          />
          <path
            className="st1"
            d="M121.15,316.21c0,2.79-0.03,5.38,0.02,7.98c0.01,0.85-0.47,0.87-1.07,0.87c-2.52,0-5.04,0-7.56,0
			c-4.56,0-9.12-0.03-13.68,0.02c-1.03,0.01-1.36-0.27-1.36-1.35c0.03-25.72,0.02-51.44,0.03-77.15c0-4.67,3.25-7.93,7.9-7.94
			c17.56-0.01,35.12,0.05,52.68-0.04c4.04-0.02,7.95,3.15,7.94,7.83c-0.01,26.8,0,53.6-0.01,80.39c0,0.28-0.03,0.55-0.05,0.93
			c-2.87,0-5.72,0-8.73,0c0-0.59,0-1.13,0-1.68c0-25.52,0-51.04,0-76.55c0-2.43,0.19-2.08-2.14-2.09c-15.72-0.01-31.44,0-47.16,0
			c-1.64,0-1.64,0-1.64,1.63c0,21.84,0,43.68,0,65.51c0,1.62,0,1.63,1.62,1.63c3.92,0,7.84,0,11.76,0
			C120.14,316.21,120.57,316.21,121.15,316.21z"
          />
          <path
            className="st1"
            d="M176.19,211.97c0.17-0.69,0.31-1.4,0.51-2.08c1.14-3.93,4.53-6.52,8.61-6.53c16.84-0.01,33.68-0.01,50.52,0
			c5.02,0,8.95,3.97,8.95,9c0,38,0,75.99,0,113.99c0,0.43,0,0.87,0,1.4c-2.91,0-5.73,0-8.76,0c0-0.51,0-1.05,0-1.59
			c-0.01-37.28-0.02-74.55-0.03-111.83c0-0.44,0.11-0.92-0.04-1.31c-0.13-0.35-0.5-0.65-0.83-0.85c-0.2-0.12-0.55-0.03-0.83-0.03
			c-15.84,0-31.68,0-47.52,0c-1.64,0-1.65,0-1.65,1.65c0,33.64,0,67.27,0,100.91c0,0.43,0.04,0.87,0.08,1.44c4.88,0,9.69,0,14.69,0
			c0,1.36,0,2.58,0,3.8c0,1.36-0.04,2.72,0.01,4.08c0.03,0.8-0.28,1.07-1.04,1.04c-0.84-0.04-1.68,0-2.52-0.01
			c-6.72-0.02-13.44-0.03-20.16-0.05C176.19,287.33,176.19,249.65,176.19,211.97z"
          />
          <path
            className="st1"
            d="M323.79,316.37c-0.06,0.47-0.18,0.94-0.18,1.41c-0.02,2.96-0.04,5.92,0.01,8.88c0.01,0.94-0.3,1.24-1.25,1.22
			c-2.47-0.06-4.95-0.02-7.62-0.02c0-0.63,0-1.17,0-1.71c-0.01-48.08-0.01-96.16-0.02-144.24c0-2.27-0.34-2.61-2.64-2.61
			c-15.2,0-30.4,0-45.6,0c-2.17,0-2.53,0.36-2.53,2.48c0,44.28,0,88.56,0,132.84c0,0.47,0,0.94,0,1.53c4.89,0,9.67,0,14.54,0
			c0.06,0.4,0.14,0.7,0.14,1c0.01,2.28-0.02,4.56,0.02,6.84c0.01,0.75-0.21,1.08-1.01,1.08c-7.16-0.02-14.32-0.02-21.48,0
			c-0.77,0-1.11-0.29-1.06-1.06c0.02-0.28,0-0.56,0-0.84c0-47.12,0.01-94.24-0.02-141.36c0-3.56,0.94-6.62,3.75-8.94
			c1.88-1.55,4.05-2.35,6.51-2.35c16,0,32-0.01,48,0c5.7,0,10.17,4.5,10.17,10.21c0,41.72,0,83.44,0.01,125.16
			c0,0.62,0.17,1.23,0.26,1.85C323.79,310.61,323.79,313.49,323.79,316.37z"
          />
          <path
            className="st1"
            d="M342.69,316.21c0.6,0,1.03,0,1.46,0c4.08,0,8.16,0.02,12.24-0.01c0.77-0.01,1.07,0.22,1.06,1.01
			c-0.03,2.24-0.03,4.48,0,6.72c0.01,0.8-0.26,1.15-1.1,1.14c-7.12-0.02-14.24-0.02-21.36,0c-0.83,0-1.19-0.29-1.09-1.12
			c0.03-0.24,0-0.48,0-0.72c0-57.39,0.01-114.79-0.02-172.18c0-4.38,1.53-7.85,5.2-10.31c1.88-1.26,3.98-1.78,6.24-1.78
			c15.2,0.01,30.4,0.04,45.6-0.01c6.67-0.02,11.46,5.53,11.45,11.6c-0.08,58.47-0.04,116.95-0.05,175.42c0,0.36,0.09,0.76-0.04,1.07
			c-0.14,0.32-0.48,0.78-0.75,0.78c-2.4,0.06-4.8,0.06-7.2,0c-0.25-0.01-0.62-0.41-0.71-0.69c-0.13-0.4-0.04-0.87-0.04-1.31
			c-0.04-58.31-0.08-116.62-0.12-174.93c0-0.51,0.02-1.07-0.16-1.53c-0.47-1.2-1.45-1.55-2.69-1.55
			c-13.12,0.02-26.24,0.01-39.36,0.01c-1.92,0-3.84-0.01-5.76,0c-1.75,0.01-2.74,0.98-2.8,2.72c-0.01,0.36,0,0.72,0,1.08
			c0,54.19,0,108.39,0,162.58C342.69,314.79,342.69,315.39,342.69,316.21z"
          />
          <path
            className="st1"
            d="M481.23,327.89c-2.88,0-5.75,0-8.77,0c-0.03-0.65-0.08-1.19-0.08-1.73c-0.03-69.19-0.07-138.38-0.04-207.57
			c0-3.84-1.03-5.42-5.33-5.38c-13.72,0.12-27.44,0.05-41.15,0.05c-3.11,0-4.41,1.32-4.41,4.47c0,37.35,0,74.71,0,112.06
			c0,28.24,0,56.47,0,84.71c0,1.71,0,1.71,1.66,1.71c3.92,0,7.84,0,11.76,0c0.43,0,0.86,0,1.41,0c0,2.84,0.01,5.54-0.03,8.25
			c0,0.2-0.35,0.46-0.58,0.56c-0.24,0.1-0.55,0.04-0.83,0.04c-6.92,0-13.84-0.03-20.76,0.03c-1.19,0.01-1.44-0.38-1.44-1.51
			c0.03-68.87,0.02-137.74,0.03-206.61c0-6.19,3.97-11.1,9.99-12.32c1.08-0.22,2.22-0.23,3.34-0.23c13.96-0.01,27.92-0.01,41.87-0.01
			c7.16,0,11.53,3.48,13.15,10.44c0.03,0.15,0.15,0.27,0.23,0.41C481.23,186.13,481.23,257.01,481.23,327.89z"
          />
          <path
            className="st1"
            d="M547.47,72.29c0.66,0.13,1.32,0.24,1.98,0.39c6.23,1.37,10.44,6.62,10.44,13.01c0,80.12,0,160.24,0,240.36
			c0,0.55,0,1.1,0,1.73c-2.95,0-5.77,0-8.76,0c0-0.62,0-1.2,0-1.79c-0.01-79.84-0.02-159.68-0.02-239.52c0-1.32-0.16-2.55-1.02-3.6
			c-0.99-1.21-2.32-1.65-3.82-1.65c-13.72,0-27.44-0.02-41.16,0.01c-3.05,0.01-4.7,1.71-4.86,4.75c-0.02,0.44-0.01,0.88-0.01,1.32
			c0,75.6,0,151.2,0,226.8c0,0.66,0,1.33,0,2.09c5,0,9.79,0,14.77,0c0,1.15,0,2.21,0,3.27c0,1.52-0.04,3.04,0.01,4.56
			c0.03,0.8-0.27,1.08-1.04,1.04c-0.68-0.04-1.36,0-2.04-0.01c-6.88-0.02-13.76-0.03-20.64-0.05c0-80,0-160,0-240
			c0.09-0.35,0.21-0.69,0.26-1.04c0.68-5.52,4.58-9.88,10.03-11.19c0.73-0.18,1.47-0.33,2.2-0.49
			C518.35,72.29,532.91,72.29,547.47,72.29z"
          />
          <path
            className="st1"
            d="M638.64,327.81c-2.99,0-5.78,0-8.78,0c0-0.6,0-1.18,0-1.76c-0.01-91.27-0.01-182.55,0-273.82
			c0-1.89-0.5-3.54-2.07-4.59c-0.88-0.59-2.04-1.05-3.08-1.06c-13.52-0.07-27.04-0.05-40.56-0.04c-2.71,0-4.96,2.19-5.07,4.89
			c-0.02,0.44-0.01,0.88-0.01,1.32c0,87.19,0,174.39,0,261.58c0,0.56,0,1.11,0,1.82c4.88,0,9.69,0,14.69,0
			c0,2.26,0.02,4.36-0.01,6.46c-0.03,2.74,0.45,2.43-2.39,2.43c-6.52,0.02-13.04,0.01-19.56,0c-1.57,0-1.58-0.01-1.58-1.61
			c0-15.12,0-30.24,0-45.36c0-75.07,0.01-150.15-0.02-225.22c0-4.36,1.1-8.2,4.31-11.28c2.62-2.52,5.74-3.9,9.38-3.9
			c13.72-0.02,27.44-0.04,41.16,0.01c7.47,0.02,13.58,6.27,13.58,13.77c0,91.59,0,183.19,0,274.78
			C638.64,326.72,638.64,327.19,638.64,327.81z"
          />
          <path
            className="st1"
            d="M657.81,316.21c1.24,0,2.31,0,3.38,0c3.44,0,6.88,0.02,10.32-0.02c0.77-0.01,1.07,0.22,1.06,1.01
			c-0.03,2.24-0.03,4.48,0,6.72c0.01,0.8-0.26,1.15-1.1,1.14c-7.12-0.02-14.24-0.02-21.36,0c-0.83,0-1.18-0.29-1.09-1.12
			c0.03-0.28,0-0.56,0-0.84c0-100.91,0.01-201.83-0.02-302.74c0-5,1.62-9.15,5.47-12.36c2.64-2.2,5.72-3.33,9.16-3.33
			c13.08-0.02,26.16-0.02,39.24,0c7.93,0.01,14.5,6.56,14.58,14.49c0,0.32,0,0.64,0,0.96c0,101.83,0,203.67,0,305.5
			c0,2.62,0.24,2.21-2.23,2.24c-1.84,0.02-3.68-0.02-5.52,0.01c-0.73,0.01-1.03-0.27-0.98-0.98c0.02-0.24,0-0.48,0-0.72
			c-0.04-37.08-0.1-74.15-0.11-111.23c-0.02-64.96-0.03-129.91,0.05-194.87c0-3.67-2.79-6.67-6.7-6.64
			c-12.48,0.08-24.96,0.03-37.44,0.03c-4.12,0-6.69,2.55-6.69,6.63c0,98.23,0,196.47,0,294.7
			C657.81,315.19,657.81,315.59,657.81,316.21z"
          />
        </g>
      </g>
      <g id="Livello_1">
        <path
          className="st2"
          d="M93.83,330.43l0,23.74c0,5.32-4.35,9.67-9.67,9.67l-59.67,0c-5.32,0-9.67-4.35-9.67-9.67l0-25.93"
        />
        <path
          className="st2"
          d="M251.3,328.24l0,21.94c0,7.51-6.14,13.65-13.65,13.65l-130.14,0c-7.51,0-13.65-6.14-13.65-13.65l0-21.94"
        />
        <path
          className="st2"
          d="M645.37,328.24l0,21.94c0,7.51-6.14,13.65-13.65,13.65l-130.14,0c-7.51,0-13.65-6.14-13.65-13.65l0-21.94"
        />
        <path
          className="st2"
          d="M487.21,328.24l0,18.92c0,9.17-7.51,16.68-16.68,16.68l-201.67,0c-9.17,0-16.68-7.51-16.68-16.68l0-18.92"
        />
        <path
          className="st2"
          d="M724.33,328.24v25.93c0,5.32-4.35,9.67-9.67,9.67l-59.67,0c-5.32,0-9.67-4.35-9.67-9.67v-25.93"
        />
        <rect x="3.6" y="328.24" className="st3" width="729.81" height="17.8" />
        <rect x="3.6" y="328.24" className="rankLine" height="17.8" />
        <g>
          <path
            className="st4"
            d="M22.27,342.28v-1.14h1.59v-6.45l-1.85,1l-0.53-1.01l2.52-1.38h1.15v7.85h1.57v1.14H22.27z"
          />
          <path
            className="st4"
            d="M100.01,342.28v-0.93c0-0.5,0.12-0.95,0.36-1.33c0.24-0.38,0.52-0.74,0.85-1.06c0.42-0.41,0.81-0.76,1.17-1.05
			c0.36-0.29,0.68-0.56,0.95-0.81s0.49-0.49,0.65-0.73c0.16-0.24,0.23-0.52,0.23-0.83c0-0.19-0.05-0.38-0.15-0.58
			s-0.26-0.37-0.48-0.51c-0.22-0.14-0.52-0.21-0.9-0.21c-0.34,0-0.63,0.06-0.88,0.18c-0.24,0.12-0.44,0.26-0.6,0.43
			s-0.29,0.33-0.41,0.49l-0.95-0.68c0.13-0.23,0.32-0.46,0.57-0.71s0.56-0.45,0.95-0.62c0.38-0.17,0.85-0.25,1.4-0.25
			c0.65,0,1.18,0.11,1.6,0.34c0.42,0.23,0.73,0.52,0.93,0.88c0.2,0.36,0.3,0.75,0.3,1.17c0,0.45-0.1,0.85-0.29,1.21
			c-0.19,0.36-0.45,0.69-0.75,1c-0.31,0.3-0.63,0.59-0.98,0.87c-0.61,0.48-1.12,0.91-1.53,1.32c-0.41,0.4-0.62,0.83-0.62,1.28h4.27
			v1.17H100.01z"
          />
          <path
            className="st4"
            d="M178.3,341.52l0.75-0.93c0.24,0.19,0.52,0.37,0.84,0.53c0.32,0.17,0.73,0.25,1.22,0.25
			c0.59,0,1.06-0.16,1.42-0.49c0.36-0.33,0.53-0.76,0.53-1.29c0-0.47-0.15-0.87-0.45-1.19s-0.74-0.48-1.32-0.48
			c-0.17,0-0.33,0.01-0.47,0.04s-0.28,0.07-0.41,0.11l-0.41-0.67l2.52-2.93h-3.59v-1.18h5.16v1.03l-2.31,2.69
			c0.8,0,1.44,0.24,1.92,0.71s0.72,1.1,0.72,1.88c0,0.63-0.15,1.16-0.44,1.59c-0.29,0.43-0.69,0.76-1.2,0.98
			c-0.5,0.22-1.06,0.33-1.68,0.33c-0.69,0-1.26-0.11-1.71-0.32S178.59,341.76,178.3,341.52z"
          />
          <path
            className="st4"
            d="M257.29,340.41v-1.1l4.07-6.03h1.3v6.01h0.97v1.11h-0.97v1.87h-1.24v-1.87H257.29z M258.56,339.3h2.86v-4.29
			L258.56,339.3z"
          />
          <path
            className="st4"
            d="M335.92,341.54l0.73-0.94c0.27,0.21,0.56,0.39,0.88,0.54c0.32,0.15,0.72,0.23,1.18,0.23
			c0.67,0,1.16-0.18,1.49-0.55c0.33-0.37,0.49-0.82,0.49-1.34c0-0.25-0.05-0.51-0.16-0.78c-0.1-0.27-0.29-0.5-0.57-0.7
			s-0.67-0.29-1.18-0.29c-0.38,0-0.69,0.04-0.92,0.11c-0.23,0.08-0.44,0.16-0.63,0.26l-0.66-0.5l0.85-4.27h4.4v1.2h-3.5l-0.41,2.35
			c0.09-0.06,0.25-0.11,0.46-0.16s0.46-0.08,0.75-0.08c0.85,0,1.54,0.24,2.09,0.73s0.82,1.18,0.82,2.09c0,0.55-0.12,1.06-0.36,1.53
			c-0.24,0.47-0.61,0.85-1.1,1.13c-0.5,0.29-1.14,0.43-1.93,0.43c-0.63,0-1.16-0.1-1.6-0.29S336.22,341.8,335.92,341.54z"
          />
          <path
            className="st4"
            d="M420.97,333.66l-0.58,1.01c-0.05-0.04-0.21-0.12-0.48-0.26c-0.28-0.13-0.62-0.2-1.04-0.2
			c-0.53,0-0.98,0.15-1.35,0.44c-0.37,0.29-0.64,0.67-0.83,1.13c-0.19,0.46-0.29,0.95-0.31,1.47c0.21-0.14,0.48-0.3,0.81-0.46
			c0.33-0.17,0.75-0.25,1.27-0.25c0.86,0,1.53,0.26,2.02,0.78c0.49,0.52,0.74,1.24,0.74,2.17c0,0.6-0.13,1.12-0.38,1.57
			c-0.25,0.45-0.6,0.81-1.05,1.06c-0.45,0.26-0.96,0.38-1.52,0.38c-1.01,0-1.79-0.36-2.36-1.08c-0.57-0.72-0.85-1.79-0.85-3.23
			c0-1.06,0.15-1.98,0.44-2.75c0.29-0.77,0.73-1.36,1.3-1.78c0.57-0.42,1.27-0.63,2.1-0.63c0.48,0,0.9,0.07,1.26,0.21
			C420.52,333.42,420.79,333.55,420.97,333.66z M416.35,338.39c0,0.93,0.15,1.66,0.45,2.19c0.3,0.53,0.78,0.8,1.45,0.8
			c0.46,0,0.86-0.17,1.21-0.5c0.35-0.33,0.53-0.8,0.53-1.41c0-0.51-0.13-0.94-0.39-1.28c-0.26-0.34-0.69-0.51-1.28-0.51
			c-0.45,0-0.83,0.07-1.15,0.21C416.84,338.04,416.57,338.2,416.35,338.39z"
          />
          <path
            className="st4"
            d="M496.31,342.28h-1.38c0.22-0.67,0.48-1.39,0.78-2.15c0.3-0.76,0.61-1.5,0.93-2.24
			c0.32-0.73,0.63-1.39,0.93-1.99c0.3-0.59,0.55-1.06,0.76-1.4h-4.42v-1.21h5.74v1.17c-0.16,0.27-0.36,0.63-0.6,1.1
			c-0.24,0.47-0.49,0.99-0.75,1.57s-0.53,1.19-0.78,1.81c-0.26,0.62-0.49,1.22-0.7,1.8S496.45,341.83,496.31,342.28z"
          />
          <path
            className="st4"
            d="M574.22,337.38c-0.38-0.2-0.7-0.46-0.97-0.8c-0.27-0.33-0.4-0.74-0.4-1.23c0-0.67,0.25-1.23,0.74-1.66
			c0.49-0.43,1.16-0.65,2.01-0.65c0.89,0,1.57,0.21,2.04,0.64c0.46,0.43,0.7,0.95,0.7,1.58c0,0.44-0.13,0.84-0.38,1.2
			c-0.25,0.37-0.6,0.65-1.05,0.86c0.24,0.09,0.51,0.25,0.8,0.46c0.3,0.21,0.56,0.49,0.79,0.83c0.23,0.34,0.34,0.75,0.34,1.23
			c0,0.4-0.11,0.8-0.33,1.21s-0.56,0.75-1.04,1.03c-0.47,0.28-1.1,0.41-1.88,0.41c-0.79,0-1.42-0.13-1.9-0.41s-0.83-0.61-1.04-1.02
			s-0.32-0.81-0.32-1.21c0-0.42,0.09-0.79,0.27-1.11c0.18-0.32,0.42-0.6,0.71-0.83C573.61,337.69,573.91,337.51,574.22,337.38z
			M575.55,337.98c-0.51,0.13-0.96,0.36-1.33,0.69c-0.37,0.33-0.56,0.71-0.56,1.16c0,0.38,0.1,0.69,0.3,0.93
			c0.2,0.24,0.45,0.41,0.75,0.51c0.3,0.1,0.6,0.16,0.89,0.16c0.3,0,0.6-0.05,0.9-0.16c0.29-0.1,0.54-0.28,0.73-0.51
			s0.29-0.55,0.29-0.93c0-0.3-0.09-0.58-0.29-0.82c-0.19-0.24-0.43-0.45-0.73-0.63C576.21,338.19,575.89,338.06,575.55,337.98z
			M575.61,336.88c0.46-0.14,0.82-0.35,1.1-0.63s0.41-0.6,0.41-0.97c0-0.35-0.14-0.64-0.41-0.88c-0.28-0.23-0.64-0.35-1.1-0.35
			c-0.45,0-0.81,0.12-1.09,0.36c-0.28,0.24-0.42,0.55-0.42,0.94c0,0.32,0.13,0.62,0.4,0.9S575.13,336.74,575.61,336.88z"
          />
          <path
            className="st4"
            d="M651.45,341.91l0.56-1.04c0.04,0.03,0.13,0.08,0.29,0.16c0.15,0.08,0.35,0.15,0.58,0.22
			c0.24,0.07,0.49,0.11,0.77,0.11c0.54,0,0.98-0.14,1.32-0.43c0.34-0.28,0.6-0.66,0.78-1.11c0.18-0.46,0.27-0.95,0.29-1.47
			c-0.17,0.14-0.42,0.29-0.76,0.46c-0.34,0.16-0.79,0.24-1.35,0.24s-1.05-0.13-1.47-0.38c-0.42-0.25-0.74-0.6-0.97-1.05
			s-0.34-0.96-0.34-1.54c0-0.6,0.13-1.13,0.39-1.58s0.62-0.81,1.07-1.07s0.96-0.38,1.53-0.38c1.01,0,1.79,0.36,2.36,1.08
			s0.85,1.8,0.85,3.23c0,1.04-0.14,1.95-0.42,2.72c-0.28,0.77-0.7,1.36-1.25,1.78s-1.24,0.63-2.07,0.63c-0.49,0-0.94-0.07-1.32-0.21
			C651.9,342.14,651.62,342.01,651.45,341.91z M656.05,337.19c0-0.93-0.15-1.66-0.45-2.19c-0.3-0.53-0.78-0.8-1.45-0.8
			c-0.3,0-0.59,0.07-0.86,0.22s-0.49,0.36-0.66,0.64s-0.25,0.63-0.26,1.03c0,0.49,0.14,0.91,0.41,1.26c0.27,0.35,0.71,0.52,1.33,0.52
			c0.47,0,0.86-0.07,1.16-0.2C655.58,337.54,655.83,337.38,656.05,337.19z"
          />
        </g>
        <g>
          <g className="wordRank">
            <path
              className="st5"
              d="M24.45,349.49h1.22l1.52,7.71h0.03l0.04-0.44l0.42-2.57l0.92-4.71h1.25l0.99,10.69h-1.39l-0.25-3.98
				l-0.08-1.98l-0.03-1.4h-0.03l-0.24,1.53l-1.11,5.84h-0.98l-1.38-7.37h-0.03l-0.41,7.37h-1.35L24.45,349.49z"
            />
            <path
              className="st5"
              d="M33.53,349.56c0.3-0.14,0.64-0.21,1.02-0.21s0.72,0.07,1.02,0.21c0.3,0.14,0.55,0.32,0.74,0.52
				c0.19,0.21,0.35,0.48,0.49,0.83c0.14,0.34,0.24,0.66,0.3,0.94c0.07,0.28,0.12,0.64,0.16,1.06c0.04,0.43,0.06,0.76,0.06,0.99
				c0,0.24,0.01,0.54,0.01,0.92c0,0.38,0,0.69-0.01,0.92c0,0.24-0.03,0.57-0.06,0.99c-0.04,0.42-0.09,0.78-0.16,1.06
				c-0.07,0.28-0.17,0.6-0.3,0.94c-0.14,0.35-0.3,0.62-0.49,0.83c-0.19,0.21-0.43,0.38-0.74,0.52c-0.3,0.14-0.64,0.21-1.02,0.21
				s-0.72-0.07-1.02-0.21c-0.3-0.14-0.55-0.32-0.74-0.52c-0.19-0.21-0.35-0.48-0.49-0.83c-0.14-0.34-0.24-0.66-0.3-0.94
				c-0.07-0.28-0.12-0.64-0.16-1.06c-0.04-0.43-0.06-0.76-0.06-0.99s-0.01-0.54-0.01-0.92c0-0.38,0-0.68,0.01-0.92
				s0.03-0.57,0.06-0.99c0.04-0.42,0.09-0.78,0.16-1.06c0.07-0.28,0.17-0.6,0.3-0.94c0.14-0.35,0.3-0.62,0.49-0.83
				C32.98,349.87,33.23,349.7,33.53,349.56z M35.57,351.75c-0.17-0.71-0.51-1.07-1.02-1.07c-0.51,0-0.85,0.36-1.02,1.07
				c-0.17,0.71-0.25,1.74-0.25,3.08c0,1.34,0.08,2.37,0.25,3.08c0.17,0.71,0.51,1.07,1.02,1.07c0.51,0,0.85-0.36,1.02-1.07
				c0.17-0.71,0.25-1.74,0.25-3.08C35.83,353.49,35.74,352.46,35.57,351.75z"
            />
            <path
              className="st5"
              d="M38.79,349.49h1.42v9.45h2.38v1.23h-3.8V349.49z"
            />
            <path
              className="st5"
              d="M42.56,349.49h4.69v1.23h-1.6v9.45h-1.42v-9.45h-1.67V349.49z"
            />
            <path
              className="st5"
              d="M49.55,349.56c0.3-0.14,0.64-0.21,1.02-0.21s0.72,0.07,1.02,0.21c0.3,0.14,0.55,0.32,0.74,0.52
				c0.19,0.21,0.35,0.48,0.49,0.83c0.14,0.34,0.24,0.66,0.3,0.94c0.07,0.28,0.12,0.64,0.16,1.06c0.04,0.43,0.06,0.76,0.06,0.99
				c0,0.24,0.01,0.54,0.01,0.92c0,0.38,0,0.69-0.01,0.92c0,0.24-0.03,0.57-0.06,0.99c-0.04,0.42-0.09,0.78-0.16,1.06
				c-0.07,0.28-0.17,0.6-0.3,0.94c-0.14,0.35-0.3,0.62-0.49,0.83c-0.19,0.21-0.43,0.38-0.74,0.52c-0.3,0.14-0.64,0.21-1.02,0.21
				s-0.72-0.07-1.02-0.21c-0.3-0.14-0.55-0.32-0.74-0.52c-0.19-0.21-0.35-0.48-0.49-0.83c-0.14-0.34-0.24-0.66-0.3-0.94
				c-0.07-0.28-0.12-0.64-0.16-1.06c-0.04-0.43-0.06-0.76-0.06-0.99s-0.01-0.54-0.01-0.92c0-0.38,0-0.68,0.01-0.92
				s0.03-0.57,0.06-0.99c0.04-0.42,0.09-0.78,0.16-1.06c0.07-0.28,0.17-0.6,0.3-0.94c0.14-0.35,0.3-0.62,0.49-0.83
				C49,349.87,49.25,349.7,49.55,349.56z M51.59,351.75c-0.17-0.71-0.51-1.07-1.02-1.07c-0.51,0-0.85,0.36-1.02,1.07
				c-0.17,0.71-0.25,1.74-0.25,3.08c0,1.34,0.08,2.37,0.25,3.08c0.17,0.71,0.51,1.07,1.02,1.07c0.51,0,0.85-0.36,1.02-1.07
				c0.17-0.71,0.25-1.74,0.25-3.08C51.84,353.49,51.76,352.46,51.59,351.75z"
            />
            <path
              className="st5"
              d="M59.41,360.17h-1.7v-10.69h1.77c0.92,0,1.56,0.24,1.92,0.71c0.36,0.47,0.55,1.19,0.55,2.15
				c0,0.4-0.03,0.74-0.09,1.03s-0.2,0.56-0.4,0.81c-0.21,0.26-0.49,0.43-0.85,0.52v0.03c0.57,0.18,0.96,0.48,1.18,0.89
				c0.22,0.42,0.33,0.95,0.33,1.62c0,1.01-0.21,1.75-0.62,2.22S60.38,360.17,59.41,360.17z M60.56,352.29c0-0.47-0.1-0.86-0.29-1.16
				c-0.19-0.3-0.52-0.45-0.97-0.44h-0.24v3.5c0.6,0.03,1.01-0.12,1.21-0.44C60.46,353.43,60.56,352.94,60.56,352.29z M59.06,355.33
				v3.64c0.59,0.03,0.99-0.1,1.21-0.39c0.22-0.29,0.33-0.74,0.33-1.37c0-0.65-0.11-1.13-0.32-1.44
				C60.07,355.47,59.66,355.32,59.06,355.33z"
            />
            <path
              className="st5"
              d="M65.15,349.49h1.12l2.3,10.69h-1.43l-0.4-2.08h-2.13l-0.4,2.08h-1.39L65.15,349.49z M66.53,356.98l-0.28-1.54
				l-0.54-3.88h-0.03l-0.85,5.43H66.53z"
            />
            <path
              className="st5"
              d="M72.81,349.7l-0.01,1.43c-0.42-0.3-0.82-0.45-1.2-0.45c-0.38,0-0.68,0.13-0.91,0.4
				c-0.23,0.27-0.35,0.59-0.35,0.97c0,0.43,0.13,0.82,0.4,1.16l1.08,1.18c0.55,0.56,0.94,1.05,1.17,1.48
				c0.23,0.43,0.35,0.98,0.35,1.65c0,0.78-0.23,1.45-0.68,1.98c-0.45,0.54-1.06,0.81-1.83,0.81c-0.62,0-1.13-0.17-1.56-0.51v-1.43
				c0.5,0.37,0.97,0.55,1.4,0.55c0.42,0,0.73-0.14,0.94-0.43c0.21-0.28,0.31-0.64,0.31-1.06c0-0.39-0.1-0.75-0.3-1.09
				c-0.2-0.34-0.45-0.65-0.75-0.92s-0.59-0.56-0.89-0.85s-0.54-0.65-0.74-1.08c-0.2-0.42-0.3-0.9-0.3-1.42c0-0.79,0.21-1.45,0.62-1.96
				s1.01-0.77,1.77-0.77C71.91,349.35,72.41,349.46,72.81,349.7z"
            />
            <path
              className="st5"
              d="M77.82,349.7l-0.01,1.43c-0.42-0.3-0.82-0.45-1.2-0.45c-0.38,0-0.68,0.13-0.91,0.4
				c-0.23,0.27-0.35,0.59-0.35,0.97c0,0.43,0.13,0.82,0.4,1.16l1.08,1.18c0.55,0.56,0.94,1.05,1.17,1.48
				c0.23,0.43,0.35,0.98,0.35,1.65c0,0.78-0.23,1.45-0.68,1.98c-0.45,0.54-1.06,0.81-1.83,0.81c-0.62,0-1.13-0.17-1.56-0.51v-1.43
				c0.5,0.37,0.97,0.55,1.4,0.55c0.42,0,0.73-0.14,0.94-0.43c0.21-0.28,0.31-0.64,0.31-1.06c0-0.39-0.1-0.75-0.3-1.09
				c-0.2-0.34-0.45-0.65-0.75-0.92s-0.59-0.56-0.89-0.85s-0.54-0.65-0.74-1.08c-0.2-0.42-0.3-0.9-0.3-1.42c0-0.79,0.21-1.45,0.62-1.96
				s1.01-0.77,1.77-0.77C76.93,349.35,77.43,349.46,77.82,349.7z"
            />
            <path
              className="st5"
              d="M81.03,349.56c0.3-0.14,0.64-0.21,1.02-0.21s0.72,0.07,1.02,0.21c0.3,0.14,0.55,0.32,0.74,0.52
				c0.19,0.21,0.35,0.48,0.49,0.83c0.14,0.34,0.24,0.66,0.3,0.94c0.07,0.28,0.12,0.64,0.16,1.06c0.04,0.43,0.06,0.76,0.06,0.99
				c0,0.24,0.01,0.54,0.01,0.92c0,0.38,0,0.69-0.01,0.92c0,0.24-0.03,0.57-0.06,0.99c-0.04,0.42-0.09,0.78-0.16,1.06
				c-0.07,0.28-0.17,0.6-0.3,0.94c-0.14,0.35-0.3,0.62-0.49,0.83c-0.19,0.21-0.43,0.38-0.74,0.52c-0.3,0.14-0.64,0.21-1.02,0.21
				s-0.72-0.07-1.02-0.21c-0.3-0.14-0.55-0.32-0.74-0.52c-0.19-0.21-0.35-0.48-0.49-0.83c-0.14-0.34-0.24-0.66-0.3-0.94
				c-0.07-0.28-0.12-0.64-0.16-1.06c-0.04-0.43-0.06-0.76-0.06-0.99s-0.01-0.54-0.01-0.92c0-0.38,0-0.68,0.01-0.92
				s0.03-0.57,0.06-0.99c0.04-0.42,0.09-0.78,0.16-1.06c0.07-0.28,0.17-0.6,0.3-0.94c0.14-0.35,0.3-0.62,0.49-0.83
				C80.48,349.87,80.72,349.7,81.03,349.56z M83.07,351.75c-0.17-0.71-0.51-1.07-1.02-1.07c-0.51,0-0.85,0.36-1.02,1.07
				c-0.17,0.71-0.25,1.74-0.25,3.08c0,1.34,0.08,2.37,0.25,3.08c0.17,0.71,0.51,1.07,1.02,1.07c0.51,0,0.85-0.36,1.02-1.07
				c0.17-0.71,0.25-1.74,0.25-3.08C83.32,353.49,83.24,352.46,83.07,351.75z"
            />
          </g>
          <g className="wordRank">
            <path
              className="st5"
              d="M160.86,360.78h-1.7v-10.69h1.77c0.92,0,1.56,0.24,1.92,0.71c0.36,0.47,0.55,1.19,0.55,2.15
				c0,0.4-0.03,0.74-0.09,1.03s-0.2,0.56-0.4,0.81c-0.21,0.26-0.49,0.43-0.85,0.52v0.03c0.57,0.18,0.96,0.48,1.18,0.89
				c0.22,0.42,0.33,0.95,0.33,1.62c0,1.01-0.21,1.75-0.62,2.22S161.82,360.78,160.86,360.78z M162,352.9c0-0.47-0.1-0.86-0.29-1.16
				c-0.19-0.3-0.52-0.45-0.97-0.44h-0.24v3.5c0.6,0.03,1.01-0.12,1.21-0.44C161.9,354.04,162,353.55,162,352.9z M160.5,355.93v3.64
				c0.59,0.03,0.99-0.1,1.21-0.39c0.22-0.29,0.33-0.74,0.33-1.37c0-0.65-0.11-1.13-0.32-1.44
				C161.51,356.07,161.11,355.92,160.5,355.93z"
            />
            <path
              className="st5"
              d="M166.6,350.09h1.12l2.3,10.69h-1.43l-0.4-2.08h-2.13l-0.4,2.08h-1.39L166.6,350.09z M167.97,357.59l-0.28-1.54
				l-0.54-3.88h-0.03l-0.85,5.43H167.97z"
            />
            <path
              className="st5"
              d="M174.25,350.3l-0.01,1.43c-0.42-0.3-0.82-0.45-1.2-0.45c-0.38,0-0.68,0.13-0.91,0.4
				c-0.23,0.27-0.35,0.59-0.35,0.97c0,0.43,0.13,0.82,0.4,1.16l1.08,1.18c0.55,0.56,0.94,1.05,1.17,1.48
				c0.23,0.43,0.35,0.98,0.35,1.65c0,0.78-0.23,1.45-0.68,1.98c-0.45,0.54-1.06,0.81-1.83,0.81c-0.62,0-1.13-0.17-1.56-0.51v-1.43
				c0.5,0.37,0.97,0.55,1.4,0.55c0.42,0,0.73-0.14,0.94-0.43c0.21-0.28,0.31-0.64,0.31-1.06c0-0.39-0.1-0.75-0.3-1.09
				c-0.2-0.34-0.45-0.65-0.75-0.92s-0.59-0.56-0.89-0.85s-0.54-0.65-0.74-1.08c-0.2-0.42-0.3-0.9-0.3-1.42c0-0.79,0.21-1.45,0.62-1.96
				s1.01-0.77,1.77-0.77C173.35,349.95,173.85,350.07,174.25,350.3z"
            />
            <path
              className="st5"
              d="M179.27,350.3l-0.01,1.43c-0.42-0.3-0.82-0.45-1.2-0.45c-0.38,0-0.68,0.13-0.91,0.4
				c-0.23,0.27-0.35,0.59-0.35,0.97c0,0.43,0.13,0.82,0.4,1.16l1.08,1.18c0.55,0.56,0.94,1.05,1.17,1.48
				c0.23,0.43,0.35,0.98,0.35,1.65c0,0.78-0.23,1.45-0.68,1.98c-0.45,0.54-1.06,0.81-1.83,0.81c-0.62,0-1.13-0.17-1.56-0.51v-1.43
				c0.5,0.37,0.97,0.55,1.4,0.55c0.42,0,0.73-0.14,0.94-0.43c0.21-0.28,0.31-0.64,0.31-1.06c0-0.39-0.1-0.75-0.3-1.09
				c-0.2-0.34-0.45-0.65-0.75-0.92s-0.59-0.56-0.89-0.85s-0.54-0.65-0.74-1.08c-0.2-0.42-0.3-0.9-0.3-1.42c0-0.79,0.21-1.45,0.62-1.96
				s1.01-0.77,1.77-0.77C178.37,349.95,178.87,350.07,179.27,350.3z"
            />
            <path
              className="st5"
              d="M182.47,350.16c0.3-0.14,0.64-0.21,1.02-0.21s0.72,0.07,1.02,0.21c0.3,0.14,0.55,0.32,0.74,0.52
				c0.19,0.21,0.35,0.48,0.49,0.83c0.14,0.34,0.24,0.66,0.3,0.94c0.07,0.28,0.12,0.64,0.16,1.06c0.04,0.43,0.06,0.76,0.06,0.99
				c0,0.24,0.01,0.54,0.01,0.92c0,0.38,0,0.69-0.01,0.92c0,0.24-0.03,0.57-0.06,0.99c-0.04,0.42-0.09,0.78-0.16,1.06
				c-0.07,0.28-0.17,0.6-0.3,0.94c-0.14,0.35-0.3,0.62-0.49,0.83c-0.19,0.21-0.43,0.38-0.74,0.52c-0.3,0.14-0.64,0.21-1.02,0.21
				s-0.72-0.07-1.02-0.21c-0.3-0.14-0.55-0.32-0.74-0.52c-0.19-0.21-0.35-0.48-0.49-0.83c-0.14-0.34-0.24-0.66-0.3-0.94
				c-0.07-0.28-0.12-0.64-0.16-1.06c-0.04-0.43-0.06-0.76-0.06-0.99s-0.01-0.54-0.01-0.92c0-0.38,0-0.68,0.01-0.92
				s0.03-0.57,0.06-0.99c0.04-0.42,0.09-0.78,0.16-1.06c0.07-0.28,0.17-0.6,0.3-0.94c0.14-0.35,0.3-0.62,0.49-0.83
				C181.92,350.48,182.17,350.3,182.47,350.16z M184.51,352.35c-0.17-0.71-0.51-1.07-1.02-1.07c-0.51,0-0.85,0.36-1.02,1.07
				c-0.17,0.71-0.25,1.74-0.25,3.08c0,1.34,0.08,2.37,0.25,3.08c0.17,0.71,0.51,1.07,1.02,1.07c0.51,0,0.85-0.36,1.02-1.07
				c0.17-0.71,0.25-1.74,0.25-3.08C184.76,354.09,184.68,353.06,184.51,352.35z"
            />
          </g>
          <g className="wordRank">
            <path
              className="st5"
              d="M356.24,350.09h1.22l1.52,7.71H359l0.04-0.44l0.42-2.57l0.92-4.71h1.25l0.99,10.69h-1.39l-0.25-3.98
				l-0.08-1.98l-0.03-1.4h-0.03l-0.24,1.53l-1.11,5.84h-0.98l-1.38-7.37h-0.03l-0.41,7.37h-1.35L356.24,350.09z"
            />
            <path
              className="st5"
              d="M363.83,350.09h3.81v1.23h-2.39v3.36h2.14v1.23h-2.14v3.63h2.39v1.23h-3.81V350.09z"
            />
            <path
              className="st5"
              d="M368.75,350.09h1.59c0.3,0,0.55,0.01,0.73,0.02c0.18,0.01,0.42,0.06,0.7,0.13c0.28,0.07,0.51,0.18,0.69,0.32
				c0.17,0.14,0.36,0.35,0.57,0.62s0.36,0.6,0.47,0.98c0.11,0.38,0.21,0.87,0.29,1.45s0.12,1.26,0.12,2.01c0,0.69-0.04,1.3-0.11,1.84
				c-0.07,0.53-0.16,0.98-0.27,1.35c-0.11,0.36-0.27,0.67-0.48,0.92s-0.41,0.44-0.61,0.58s-0.46,0.24-0.79,0.31
				c-0.33,0.07-0.63,0.12-0.89,0.13s-0.61,0.03-1.03,0.03h-0.98V350.09z M372.41,355.62c0-0.06,0-0.15,0-0.28c0-0.33,0-0.59,0-0.77
				s-0.01-0.44-0.04-0.77c-0.03-0.33-0.07-0.6-0.12-0.8s-0.13-0.42-0.24-0.68c-0.11-0.26-0.24-0.45-0.4-0.58
				c-0.16-0.13-0.36-0.24-0.6-0.33c-0.25-0.09-0.52-0.13-0.84-0.11v8.28c0.31,0.01,0.59-0.03,0.83-0.11c0.24-0.08,0.44-0.17,0.59-0.28
				c0.16-0.11,0.29-0.28,0.4-0.5c0.11-0.23,0.19-0.42,0.25-0.6c0.06-0.17,0.1-0.42,0.12-0.74s0.04-0.57,0.04-0.74
				c0-0.17,0.01-0.43,0.01-0.78C372.41,355.74,372.41,355.67,372.41,355.62z"
            />
            <path
              className="st5"
              d="M376.77,350.09v10.69h-1.42v-10.69H376.77z"
            />
            <path
              className="st5"
              d="M379.99,350.16c0.3-0.14,0.64-0.21,1.02-0.21s0.72,0.07,1.02,0.21c0.3,0.14,0.55,0.32,0.74,0.52
				c0.19,0.21,0.35,0.48,0.49,0.83c0.14,0.34,0.24,0.66,0.3,0.94c0.07,0.28,0.12,0.64,0.16,1.06c0.04,0.43,0.06,0.76,0.06,0.99
				c0,0.24,0.01,0.54,0.01,0.92c0,0.38,0,0.69-0.01,0.92c0,0.24-0.03,0.57-0.06,0.99c-0.04,0.42-0.09,0.78-0.16,1.06
				c-0.07,0.28-0.17,0.6-0.3,0.94c-0.14,0.35-0.3,0.62-0.49,0.83c-0.19,0.21-0.43,0.38-0.74,0.52c-0.3,0.14-0.64,0.21-1.02,0.21
				s-0.72-0.07-1.02-0.21c-0.3-0.14-0.55-0.32-0.74-0.52c-0.19-0.21-0.35-0.48-0.49-0.83c-0.14-0.34-0.24-0.66-0.3-0.94
				c-0.07-0.28-0.12-0.64-0.16-1.06c-0.04-0.43-0.06-0.76-0.06-0.99s-0.01-0.54-0.01-0.92c0-0.38,0-0.68,0.01-0.92
				s0.03-0.57,0.06-0.99c0.04-0.42,0.09-0.78,0.16-1.06c0.07-0.28,0.17-0.6,0.3-0.94c0.14-0.35,0.3-0.62,0.49-0.83
				C379.44,350.48,379.69,350.3,379.99,350.16z M382.03,352.35c-0.17-0.71-0.51-1.07-1.02-1.07c-0.51,0-0.85,0.36-1.02,1.07
				c-0.17,0.71-0.25,1.74-0.25,3.08c0,1.34,0.08,2.37,0.25,3.08c0.17,0.71,0.51,1.07,1.02,1.07c0.51,0,0.85-0.36,1.02-1.07
				c0.17-0.71,0.25-1.74,0.25-3.08C382.29,354.09,382.2,353.06,382.03,352.35z"
            />
          </g>
          <g className="wordRank">
            <path
              className="st5"
              d="M558.09,350.09h1.12l2.3,10.69h-1.43l-0.4-2.08h-2.13l-0.4,2.08h-1.39L558.09,350.09z M559.47,357.59
				l-0.28-1.54l-0.54-3.88h-0.03l-0.85,5.43H559.47z"
            />
            <path
              className="st5"
              d="M562.44,350.09h1.42v9.45h2.38v1.23h-3.8V350.09z"
            />
            <path
              className="st5"
              d="M566.21,350.09h4.69v1.23h-1.6v9.45h-1.42v-9.45h-1.67V350.09z"
            />
            <path
              className="st5"
              d="M573.2,350.16c0.3-0.14,0.64-0.21,1.02-0.21s0.72,0.07,1.02,0.21c0.3,0.14,0.55,0.32,0.74,0.52
				c0.19,0.21,0.35,0.48,0.49,0.83c0.14,0.34,0.24,0.66,0.3,0.94c0.07,0.28,0.12,0.64,0.16,1.06c0.04,0.43,0.06,0.76,0.06,0.99
				c0,0.24,0.01,0.54,0.01,0.92c0,0.38,0,0.69-0.01,0.92c0,0.24-0.03,0.57-0.06,0.99c-0.04,0.42-0.09,0.78-0.16,1.06
				c-0.07,0.28-0.17,0.6-0.3,0.94c-0.14,0.35-0.3,0.62-0.49,0.83c-0.19,0.21-0.43,0.38-0.74,0.52c-0.3,0.14-0.64,0.21-1.02,0.21
				s-0.72-0.07-1.02-0.21c-0.3-0.14-0.55-0.32-0.74-0.52c-0.19-0.21-0.35-0.48-0.49-0.83c-0.14-0.34-0.24-0.66-0.3-0.94
				c-0.07-0.28-0.12-0.64-0.16-1.06c-0.04-0.43-0.06-0.76-0.06-0.99s-0.01-0.54-0.01-0.92c0-0.38,0-0.68,0.01-0.92
				s0.03-0.57,0.06-0.99c0.04-0.42,0.09-0.78,0.16-1.06c0.07-0.28,0.17-0.6,0.3-0.94c0.14-0.35,0.3-0.62,0.49-0.83
				C572.65,350.48,572.9,350.3,573.2,350.16z M575.24,352.35c-0.17-0.71-0.51-1.07-1.02-1.07c-0.51,0-0.85,0.36-1.02,1.07
				c-0.17,0.71-0.25,1.74-0.25,3.08c0,1.34,0.08,2.37,0.25,3.08c0.17,0.71,0.51,1.07,1.02,1.07c0.51,0,0.85-0.36,1.02-1.07
				c0.17-0.71,0.25-1.74,0.25-3.08C575.5,354.09,575.41,353.06,575.24,352.35z"
            />
          </g>
          <g className="wordRank">
            <path
              className="st5"
              d="M658.29,350.09h1.22l1.52,7.71h0.03l0.04-0.44l0.42-2.57l0.92-4.71h1.25l0.99,10.69h-1.39l-0.25-3.98
				l-0.08-1.98l-0.03-1.4h-0.03l-0.24,1.53l-1.11,5.84h-0.98l-1.38-7.37h-0.03l-0.41,7.37h-1.35L658.29,350.09z"
            />
            <path
              className="st5"
              d="M667.38,350.16c0.3-0.14,0.64-0.21,1.02-0.21s0.72,0.07,1.02,0.21c0.3,0.14,0.55,0.32,0.74,0.52
				c0.19,0.21,0.35,0.48,0.49,0.83c0.14,0.34,0.24,0.66,0.3,0.94c0.07,0.28,0.12,0.64,0.16,1.06c0.04,0.43,0.06,0.76,0.06,0.99
				c0,0.24,0.01,0.54,0.01,0.92c0,0.38,0,0.69-0.01,0.92c0,0.24-0.03,0.57-0.06,0.99c-0.04,0.42-0.09,0.78-0.16,1.06
				c-0.07,0.28-0.17,0.6-0.3,0.94c-0.14,0.35-0.3,0.62-0.49,0.83c-0.19,0.21-0.43,0.38-0.74,0.52c-0.3,0.14-0.64,0.21-1.02,0.21
				s-0.72-0.07-1.02-0.21c-0.3-0.14-0.55-0.32-0.74-0.52c-0.19-0.21-0.35-0.48-0.49-0.83c-0.14-0.34-0.24-0.66-0.3-0.94
				c-0.07-0.28-0.12-0.64-0.16-1.06c-0.04-0.43-0.06-0.76-0.06-0.99s-0.01-0.54-0.01-0.92c0-0.38,0-0.68,0.01-0.92
				s0.03-0.57,0.06-0.99c0.04-0.42,0.09-0.78,0.16-1.06c0.07-0.28,0.17-0.6,0.3-0.94c0.14-0.35,0.3-0.62,0.49-0.83
				C666.83,350.48,667.07,350.3,667.38,350.16z M669.42,352.35c-0.17-0.71-0.51-1.07-1.02-1.07c-0.51,0-0.85,0.36-1.02,1.07
				c-0.17,0.71-0.25,1.74-0.25,3.08c0,1.34,0.08,2.37,0.25,3.08c0.17,0.71,0.51,1.07,1.02,1.07c0.51,0,0.85-0.36,1.02-1.07
				c0.17-0.71,0.25-1.74,0.25-3.08C669.67,354.09,669.59,353.07,669.42,352.35z"
            />
            <path
              className="st5"
              d="M672.63,350.09h1.42v9.45h2.38v1.23h-3.8V350.09z"
            />
            <path
              className="st5"
              d="M676.4,350.09h4.69v1.23h-1.6v9.45h-1.42v-9.45h-1.67V350.09z"
            />
            <path
              className="st5"
              d="M683.39,350.16c0.3-0.14,0.64-0.21,1.02-0.21s0.72,0.07,1.02,0.21c0.3,0.14,0.55,0.32,0.74,0.52
				c0.19,0.21,0.35,0.48,0.49,0.83c0.14,0.34,0.24,0.66,0.3,0.94c0.07,0.28,0.12,0.64,0.16,1.06c0.04,0.43,0.06,0.76,0.06,0.99
				c0,0.24,0.01,0.54,0.01,0.92c0,0.38,0,0.69-0.01,0.92c0,0.24-0.03,0.57-0.06,0.99c-0.04,0.42-0.09,0.78-0.16,1.06
				c-0.07,0.28-0.17,0.6-0.3,0.94c-0.14,0.35-0.3,0.62-0.49,0.83c-0.19,0.21-0.43,0.38-0.74,0.52c-0.3,0.14-0.64,0.21-1.02,0.21
				s-0.72-0.07-1.02-0.21c-0.3-0.14-0.55-0.32-0.74-0.52c-0.19-0.21-0.35-0.48-0.49-0.83c-0.14-0.34-0.24-0.66-0.3-0.94
				c-0.07-0.28-0.12-0.64-0.16-1.06c-0.04-0.43-0.06-0.76-0.06-0.99s-0.01-0.54-0.01-0.92c0-0.38,0-0.68,0.01-0.92
				s0.03-0.57,0.06-0.99c0.04-0.42,0.09-0.78,0.16-1.06c0.07-0.28,0.17-0.6,0.3-0.94c0.14-0.35,0.3-0.62,0.49-0.83
				C682.84,350.48,683.09,350.3,683.39,350.16z M685.43,352.35c-0.17-0.71-0.51-1.07-1.02-1.07c-0.51,0-0.85,0.36-1.02,1.07
				c-0.17,0.71-0.25,1.74-0.25,3.08c0,1.34,0.08,2.37,0.25,3.08c0.17,0.71,0.51,1.07,1.02,1.07c0.51,0,0.85-0.36,1.02-1.07
				c0.17-0.71,0.25-1.74,0.25-3.08C685.69,354.09,685.6,353.07,685.43,352.35z"
            />
            <path
              className="st5"
              d="M693.07,350.09h1.12l2.3,10.69h-1.43l-0.4-2.08h-2.13l-0.4,2.08h-1.39L693.07,350.09z M694.45,357.59
				l-0.28-1.54l-0.54-3.88h-0.03l-0.85,5.43H694.45z"
            />
            <path
              className="st5"
              d="M697.42,350.09h1.42v9.45h2.38v1.23h-3.8V350.09z"
            />
            <path
              className="st5"
              d="M701.19,350.09h4.69v1.23h-1.6v9.45h-1.42v-9.45h-1.67V350.09z"
            />
            <path
              className="st5"
              d="M708.18,350.16c0.3-0.14,0.64-0.21,1.02-0.21s0.72,0.07,1.02,0.21c0.3,0.14,0.55,0.32,0.74,0.52
				c0.19,0.21,0.35,0.48,0.49,0.83c0.14,0.34,0.24,0.66,0.3,0.94c0.07,0.28,0.12,0.64,0.16,1.06c0.04,0.43,0.06,0.76,0.06,0.99
				c0,0.24,0.01,0.54,0.01,0.92c0,0.38,0,0.69-0.01,0.92c0,0.24-0.03,0.57-0.06,0.99c-0.04,0.42-0.09,0.78-0.16,1.06
				c-0.07,0.28-0.17,0.6-0.3,0.94c-0.14,0.35-0.3,0.62-0.49,0.83c-0.19,0.21-0.43,0.38-0.74,0.52c-0.3,0.14-0.64,0.21-1.02,0.21
				s-0.72-0.07-1.02-0.21c-0.3-0.14-0.55-0.32-0.74-0.52c-0.19-0.21-0.35-0.48-0.49-0.83c-0.14-0.34-0.24-0.66-0.3-0.94
				c-0.07-0.28-0.12-0.64-0.16-1.06c-0.04-0.43-0.06-0.76-0.06-0.99s-0.01-0.54-0.01-0.92c0-0.38,0-0.68,0.01-0.92
				s0.03-0.57,0.06-0.99c0.04-0.42,0.09-0.78,0.16-1.06c0.07-0.28,0.17-0.6,0.3-0.94c0.14-0.35,0.3-0.62,0.49-0.83
				C707.63,350.48,707.88,350.3,708.18,350.16z M710.22,352.35c-0.17-0.71-0.51-1.07-1.02-1.07c-0.51,0-0.85,0.36-1.02,1.07
				c-0.17,0.71-0.25,1.74-0.25,3.08c0,1.34,0.08,2.37,0.25,3.08c0.17,0.71,0.51,1.07,1.02,1.07c0.51,0,0.85-0.36,1.02-1.07
				c0.17-0.71,0.25-1.74,0.25-3.08C710.47,354.09,710.39,353.07,710.22,352.35z"
            />
          </g>
        </g>
        <line className="st6" x1="3.6" y1="328.24" x2="733.41" y2="328.24" />
        <line className="st7" x1="14.82" y1="280.51" x2="14.82" y2="346.04" />
        <line className="st7" x1="93.85" y1="247.51" x2="93.85" y2="346.04" />
        <line className="st7" x1="172.62" y1="212.17" x2="172.62" y2="346.04" />
        <line className="st7" x1="251.66" y1="179.51" x2="251.66" y2="346.04" />
        <line className="st7" x1="330.18" y1="149.26" x2="330.18" y2="346.04" />
        <line className="st7" x1="409.22" y1="113.26" x2="409.22" y2="346.04" />
        <line className="st7" x1="487.74" y1="81.76" x2="487.74" y2="346.04" />
        <line className="st7" x1="566.78" y1="47.26" x2="566.78" y2="346.04" />
        <line className="st7" x1="645.31" y1="11.01" x2="645.31" y2="346.04" />
      </g>
    </svg>
  );
});
