export default {
  title: "Política de privacidad y uso de «cookies»",
  read: "He leído y acepto los términos y condiciones de uso.",
  text_nominale_footer: `
      <p>
      PlayYourJob es una plataforma de Laborplay S.r.l., NIF: 06563810487,
      con domicilio social en Via Madonna del Piano núm. 6, Sesto Fiorentino (FI),
      correo electrónico:&#32;
      <a href="mailto:privacy@laborplay.com">privacy@laborplay.com</a> ;
      correo electrónico certificado:&#32;
      <a href="mailto:laborplay@legalmail.it">laborplay@legalmail.it</a>.
      </p>
      <h5>
      INFORMACIÓN SOBRE EL TRATAMIENTO DE DATOS PERSONALES EN VIRTUD DEL ART. 13 
      DEL REGLAMENTO DE LA UE 2016/679 (RGPD)
      </h5>
      <h6>1. Titulares y responsables del tratamiento</h6>
      <p>
      El responsable del tratamiento de los datos es: Laborplay S.r.l., con 
      número de IVA 06563810487, con domicilio social en: Via Madonna del Piano 
      n. 6, Sesto Fiorentino (FI) a quien podrá acudir para ejercer los derechos 
      reconocidos por el RGPD y conocer la lista actualizada de todos los 
      responsables del tratamiento de datos que hayan sido designados.
      </p>
      <p>La Representante legal es: Elena Gaiffi.</p>
      <p>
      Se puede poner en contacto con el responsable del tratamiento de datos en 
      las siguientes direcciones:
      </p>
      <p>email: privacy@laborplay.com; pec: laborplay@legalmail.it.</p>
      <p>
      El responsable del tratamiento ha designado al Sr. Andrea Appicciafuoco 
      (correo electrónico: rpd.appicciafuoco@gmail.com) como responsable de la 
      protección de datos personales ("R.P.D.").
      </p>
      <h6>2. Finalidad del tratamiento</h6>
      <p>
      Los datos personales suministrados podrán ser tratados únicamente para las 
      siguientes finalidades: ejecución del servicio ofrecido por el 
      Responsable; envío de comunicaciones de servicio; cumplimiento de 
      obligaciones legales y cumplimiento de requerimientos de las autoridades.
      </p>
      <p>
      No se prevé ningún otro tratamiento basado en los intereses legítimos que 
      persigue el responsable del tratamiento.
      </p>
      <h6>3. Base jurídica del tratamiento</h6>
      <p>
      El responsable del tratamiento trata los datos personales de forma legal:
      </p>
      <ul>
      <li>
          es necesario para la celebración de un contrato en el que el 
          interesado es parte o para la aplicación de medidas precontractuales 
          adoptadas a solicitud del interesado;
      </li>
      <li>
          se basa en el consentimiento expreso del interesado para uno o más 
          fines;
      </li>
      <li>
          es necesario para cumplir con una obligación legal a la que está 
          sujeto el Responsable del tratamiento.
      </li>
      </ul>
      <h6>4. Tipos de datos tratados<h6>
      <p>
      Datos comunes (solo a modo de ejemplo: año de nacimiento, sexo).
      </p>
      <p>
      No se tratarán los datos pertenecientes a sujetos de edad inferior a la   
      mínima prevista por la legislación vigente (menores de edad). El 
      responsable del tratamiento no utiliza «cookies», por lo tanto, no se 
      tratarán los datos procedentes del uso de las mismas.
      </p>
      <h6>5. Consecuencias de la falta de comunicación de datos personales</h6>
      <p>
      La comunicación de datos personales es necesaria para poder utilizar el 
      servicio ofrecido por el Responsable del tratamiento.
      </p>
      <h6>6. Comunicación y difusión de los datos</h6>
      <p>
      Los datos solo podrán ser comunicados al interesado, a la sociedad con la 
      que el responsable haya celebrado el contrato de uso de la web app 
      propiedad del responsable, para cumplir con las obligaciones legales a las 
      que este está sujeto o, si fuera necesario, para ejecutar una tarea de 
      interés público en la que el responsable Titular del tratamiento esté 
      involucrado. Dentro de los límites pertinentes y para la consecución de 
      los fines del tratamiento indicados anteriormente, se podrán proporcionar 
      sus datos personales a:
      </p>
      <ul>
      <li>
          la Sociedad que ha celebrado el contrato de uso de la web app 
          propiedad del Responsable;
      </li>
      <li>
          empleados o colaboradores del responsable designados como 
          "encargados";
      </li>
      <li>
          autoridades judiciales o administrativas para cumplir con las 
          obligaciones legales.
      </li>
      </ul>
      <p>
      Sus datos personales no serán objeto de divulgación. Los datos no se 
      transferirán a un tercer país ni a una organización internacional.
      </p>
      <h6>7. Creación de perfiles</h6>
      <p>
      Sus datos personales estarán sujetos a la elaboración de perfiles solo con 
      su consentimiento expreso. La elaboración de perfiles se realiza mediante 
      el uso por parte del usuario de la aplicación propiedad del responsable.
      </p>
      <h6>8. Modalidades del tratamiento</h6>
      <p>
      El tratamiento de datos personales consiste en la recogida, el registro, 
      la organización, la estructuración, el almacenamiento, la adaptación o la 
      modificación, la recuperación, la consulta, la utilización, la 
      comunicación, la difusión, la comparación o la interconexión, la 
      limitación, el borrado o la destrucción de dichos datos.
      </p>
      <p>
      Los datos personales se tratan para los fines mencionados, de conformidad 
      con el artículo 5 del RGPD, en:
      </p>
      <ul>
      <li>
          soporte informático (incluidos los dispositivos portátiles) y por 
          medios telemáticos (es decir, correo electrónico); en cumplimiento de 
          lo dispuesto en el art. 32 del RGDP relativo a las medidas de 
          seguridad y en cumplimiento de lo dispuesto en el art. 29 del RGDP.
      </li>
      </ul>
      <h6>9. Conservación de los datos</h6>
      <p>
      Sus datos se almacenarán en la "nube" en servidores ubicados dentro de la 
      Unión Europea y se conservarán durante el tiempo que sea necesario para la 
      consecución de los fines indicados.
      </p>
      <p>
      Sus datos se guardarán durante el periodo de vigencia del contrato 
      estipulado para el uso de la web app propiedad del responsable y, 
      posteriormente, durante 30 días más a partir de la fecha de vencimiento de 
      dicho contrato. Transcurrido el plazo mencionado anteriormente, se 
      eliminarán sus datos personales;
      </p>
      <h6>10. Derechos del interesado</h6>
      <p> Tiene derecho a: </p>
        <ul>
        <li> para solicitar al responsable del tratamiento el acceso a los datos personales </li>
        <li> la rectificación de los datos inexactos o la integración de los incompletos </li>
        <li> a la posible cancelación de los datos personales que le conciernen (cuando concurra una de las condiciones indicadas en el art. 17, apartado 1, RGPD, y en cumplimiento de las excepciones previstas en el apartado 3 del mismo artículo), sin perjuicio de lo dispuesto en el punto 9 de este aviso informativo; </li>
        <li> la posible limitación del tratamiento de sus datos personales; </li>
        <li> si el tratamiento se lleva a cabo por medios automatizados (informáticos) y en la medida en que sea técnicamente posible, recibir en un formato estructurado o transmitir a él o a los terceros indicados por él la información que le concierne, </ li>
        <li> a retirar su consentimiento en cualquier momento (sin perjuicio de la licitud del tratamiento basado en el consentimiento antes de la revocación), obviamente para los tratamientos realizados en base a este supuesto </li>
        <li> si la respuesta a sus solicitudes no es satisfactoria o si lo considera necesario, presentar una queja ante el Garante para la Protección de Datos Personales (http://www.garanteprivacy.it/) - Piazza Venezia n. 11 00186 ROMA - Centralita telefónica: (+39) 06.696771 - E-mail: garante@gpdp.it - ​​correo certificado protocol@pec.gpdp.it </li>
        </ul>
        <p>
        Para hacer valer sus derechos, el interesado podrá dirigirse directamente al Responsable del tratamiento a través de los datos de contacto indicados anteriormente, teniendo en cuenta que no será posible atender las solicitudes recibidas por teléfono si no se tiene certeza de la identidad del solicitante.
        </p>  
  `,
  text_nominale: `
      <p>
      PlayYourJob es una plataforma de Laborplay S.r.l., NIF: 06563810487,
      con domicilio social en Via Madonna del Piano núm. 6, Sesto Fiorentino (FI),
      correo electrónico:&#32;
      <a href="mailto:privacy@laborplay.com">privacy@laborplay.com</a> ;
      correo electrónico certificado:&#32;
      <a href="mailto:laborplay@legalmail.it">laborplay@legalmail.it</a>.
      </p>
      <h5>DOCUMENTO INFORMATIVO SOBRE EL TRATAMIENTO DE LOS DATOS PERSONALES</h5>
      <h6>1) ¿QUÉ ES EL TRATAMIENTO DE DATOS PERSONALES?</h6>
      <p>El tratamiento de los datos personales es la recopilación, registro, organización, estructuración, conservación, adaptación o modificación, extracción, consulta, uso, comunicación, difusión, comparación o interconexión, limitación, cancelación o destrucción de dichos datos.</p>
      <h6>2) ¿QUIÉN ES EL RESPONSABLE DEL TRATAMIENTO DE MIS DATOS PERSONALES?</h6>
      <p>El responsable del tratamiento de los datos es Laborplay S.r.l., con domicilio social en Via Madonna del Piano núm. 6, Sesto Fiorentino (FI), NIF: 06563810487, correo electrónico: privacy@laborplay.com; correo electrónico certificado: laborplay@legalmail.it</p>
      <h6>3) ¿CON QUÉ FINES SE TRATAN MIS DATOS?</h6>
      <p>Para realizar el servicio ofrecido por el responsable, para enviarte comunicaciones inherentes al propio servicio y para cumplir tanto con las obligaciones legales como con los requerimientos de las autoridades competentes.</p>
      <h6>4) ¿CUÁLES SON LAS BASES LEGALES PARA EL TRATAMIENTO DE MIS DATOS?</h6>
      <p>Tratamos tus datos de forma lícita siempre que el tratamiento sea necesario para ejecutar el contrato del cual eres parte o para ejecutar las medidas precontractuales adoptadas a petición tuya; se basa en tu consentimiento expreso, cuando se exija, y es necesario tanto para cumplir con las obligaciones legales de las que el responsable es objeto como para la consecución del legítimo interés de este. Los intereses legítimos del responsable incluyen tanto su protección como la de los interesados u otros sujetos frente a amenazas de seguridad o fraude de conformidad con la legislación vigente, además de la gestión y mejora de la empresa.</p>
      <h6>5) ¿QUÉ DATOS PERSONALES SON OBJETO DE TRATAMIENTO?</h6>
      <ul>
        <li> Datos comunes facilitados por el interesado o por los sujetos autorizados por éste-delegados como, a título enunciativo, sexo y año de nacimiento.</li>
        <li> Datos comunes ingresados ​​durante la compilación de la prueba. </li>
        <li> Calificaciones y perfiles generados internamente por Laborplay®. </li>
        </ul>
        <p>
        Los datos de los menores serán tratados con el consentimiento informado y expreso de quien ejerza la patria potestad.
        </p>
        <p>
        El Responsable del Tratamiento no utiliza cookies, por lo que no se tratarán datos derivados de su uso.
        </p>
      <h6>6) ¿QUÉ OCURRE SI NO FACILITO MIS DATOS PERSONALES?</h6>
      <p>Si no facilitas tus datos personales, no podrás utilizar el servicio que ofrece el responsable.</p>
      <h6>7) ¿A QUIÉN SE LE PROPORCIONARÁN MIS DATOS?</h6>
      <p>Se podrán proporcionar tus datos, dentro de los límites pertinentes y para los fines anteriormente mencionados, a:</p>
      <p>ti mismo, a la sociedad con la que el responsable haya celebrado el contrato de uso de la web app propiedad del responsable, para cumplir con las obligaciones legales a las que este está sujeto o, si fuera necesario, para ejecutar una tarea de interés público en la que el responsable Titular del tratamiento esté involucrado.</p>
      <p>Dentro de los límites pertinentes y para la consecución de los fines del tratamiento indicados anteriormente, se podrán proporcionar tus datos personales a:</p>
      <ul>
      <li>la sociedad que ha celebrado el contrato de uso de la web app propiedad del responsable;</li>
      <li>empleados o colaboradores del responsable designados como «encargados»;</li>
      <li>autoridades judiciales o administrativas para cumplir con las obligaciones legales;</li>
      <li>sujetos debidamente designados por el responsable como «encargados del tratamiento» o que presten servicios funcionales para los fines indicados anteriormente;</li>
      <li>sujetos que traten los datos para ejecutar obligaciones legales específicas.</li>
      </ul>
      <p>Tus datos NO serán objeto de divulgación.</p>
      <p>Tus datos NO se transferirán a ningún país que no pertenezca a la Unión Europea ni a ninguna organización internacional;</p>
      <h6>8) ¿CÓMO SE TRATAN MIS DATOS? ¿SE UTILIZAN PARA CREAR PERFILES?</h6>
      <p>El tratamiento de tus datos personales se realiza mediante un soporte informático (incluidos dispositivos portátiles) de conformidad con lo dispuesto en la legislación vigente.</p>
      <p>Tus datos personales estarán sujetos a la elaboración de perfiles solo con tu consentimiento expreso. La elaboración de perfiles se realiza mediante el uso por parte del usuario de la aplicación propiedad del responsable.</p>
      <h6>9) ¿DURANTE CUÁNTO TIEMPO SE GUARDARÁN MIS DATOS?</h6>
      <p>Tus datos se almacenarán en la «nube» en servidores ubicados dentro de la Unión Europea y se conservarán durante el tiempo que sea necesario para la consecución de los fines indicados.</p>
      <p>Tus datos se guardarán durante el periodo de vigencia del contrato celebrado con la sociedad para utilizar la web app propiedad del responsable y, posteriormente, durante 30 días más a partir de la fecha de vencimiento de dicho contrato. Transcurrido el plazo mencionado anteriormente, se eliminarán tus datos.</p>
      <h6>10) ¿A QUÉ TENGO DERECHO DE ACUERDO CON LA LEGISLACIÓN VIGENTE EN MATERIA DE PRIVACIDAD?</h6>
      <p>Tienes derecho a:</p>
        <ul>
        <li> para solicitar al responsable del tratamiento el acceso a los datos personales </li>
        <li> la rectificación de los datos inexactos o la integración de los incompletos </li>
        <li> a la posible cancelación de los datos personales que le conciernen (cuando concurra una de las condiciones indicadas en el art. 17, apartado 1, RGPD, y en cumplimiento de las excepciones previstas en el apartado 3 del mismo artículo), sin perjuicio de lo dispuesto en el punto 9 de este aviso informativo; </li>
        <li> la posible limitación del tratamiento de sus datos personales; </li>
        <li> si el tratamiento se lleva a cabo por medios automatizados (informáticos) y en la medida en que sea técnicamente posible, recibir en un formato estructurado o transmitir a él o a los terceros indicados por él la información que le concierne, </ li>
        <li> a retirar su consentimiento en cualquier momento (sin perjuicio de la licitud del tratamiento basado en el consentimiento antes de la revocación), obviamente para los tratamientos realizados en base a este supuesto </li>
        <li> si la respuesta a sus solicitudes no es satisfactoria o si lo considera necesario, presentar una queja ante el Garante para la Protección de Datos Personales (http://www.garanteprivacy.it/) - Piazza Venezia n. 11 00186 ROMA - Centralita telefónica: (+39) 06.696771 - E-mail: garante@gpdp.it - ​​correo certificado protocol@pec.gpdp.it </li>
        </ul>
        <p>
        Para hacer valer sus derechos, el interesado podrá dirigirse directamente al Responsable del tratamiento a través de los datos de contacto indicados anteriormente, teniendo en cuenta que no será posible atender las solicitudes recibidas por teléfono si no se tiene certeza de la identidad del solicitante.
        </p>
  `,
  text_anonimo: `
      <p>
      PlayYourJob es una plataforma de Laborplay S.r.l., NIF: 06563810487,
      con domicilio social en Via Madonna del Piano núm. 6, Sesto Fiorentino (FI),
      correo electrónico:&#32;
      <a href="mailto:privacy@laborplay.com">privacy@laborplay.com</a> ;
      correo electrónico certificado:&#32;
      <a href="mailto:laborplay@legalmail.it">laborplay@legalmail.it</a>.
      </p>
      <p>
      PlayYourJob no recopila información y datos personales, ni que permitan 
      rastrear a la persona que los proporcionó. El acceso a la plataforma se 
      realiza mediante el uso de tokens anónimos y credenciales de acceso 
      generadas automáticamente por el sistema en nombre de una empresa que 
      solicita el servicio. La información ingresada en la plataforma 
      PlayYourJob (género, edad, preferencias de juego) es genérica y no permite 
      rastrear la identidad del encuestado. Los datos facilitados podrán ser 
      tratados únicamente para los siguientes fines: ejecución del servicio 
      PlayYourJob con especial referencia al uso de los juegos y participación 
      en el cuestionario de creación de perfiles con el fin de llevar a cabo la 
      creación automática de perfiles de trabajo/concordancia en nombre de la 
      empresa que solicita el servicio. La empresa que solicita el servicio se 
      encarga de relacionar los tokens con los usuarios y es la única que conoce 
      sus datos personales (nombre, apellidos, correo electrónico, etc.). Los 
      datos y la información del usuario son procesados por Laborplay y 
      devueltos de forma anónima en relación con el Token único a la empresa que 
      ha solicitado el servicio, a menos que Laborplay haya sido designado 
      expresamente como procesador de datos de conformidad con la ley. Las 
      cookies utilizadas se utilizan para optimizar la experiencia del usuario y 
      facilitar la navegación entre páginas. Los datos y las respuestas 
      proporcionadas se almacenan de conformidad con la ley en los servidores de 
      AWS Amazon ubicados dentro de la UE en nombre de Laborplay.
      </p>
  `,
};
