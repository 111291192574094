import terms from "./terms/it";
import areas from "./areas/it";

export default {
  translation: {
    login: {
      title: "Accedi",
      insert:
        "Inserisci il codice alfanumerico di 10 cifre che ti è stato fornito:",
      or: "oppure",
      qr: "Inquadra il QR Code",
      qr_back: "Torna indietro",
      exit: "Esci",
      retry: "Riprova",
      failed_1: "Autenticazione fallita.",
      failed_2:
        "Assicurati di aver digitato bene il token rispettando maiuscole e minuscole.",
      failed_active:
        "Il tuo token non è ancora attivo. Lo sarà a partire dalla seguente data:",
      failed_expired: "Il tuo token non è più valido.",
      session_warning:
        'Hai già una sessione aperta in locale. Se intendi terminare la sessione corrente e iniziarne una nuova premi su "EXIT". Altrimenti ignora questo avviso.',
    },
    footer: {
      help: "Help! Il mio codice o QR non funzionano",
    },
    overlay: {
      close: "CHIUDI",
      undo: "ANNULLA",
      help: "Hai bisogno di aiuto? Scrivi una mail all'indirizzo:",
      alert_valid: "Prima di continuare scegli un'opzione valida!",
      alert_terms:
        "Prima di procedere devi rispondere alle domande anagrafiche e accettare i termini e le condizioni! Controlla anche di aver inserito i dati in maniera corretta.",
      confirm_vincolato:
        "Stai per avviare la partita. Una volta cliccato su OK, avrai 20 minuti di tempo per ottenere il punteggio migliore. Assicurati di aver letto bene e compreso tutte le istruzioni. Assicurati inoltre di avere tempo a disposizione, una connessione stabile ed una postazione comoda e silenziosa. Ti suggeriamo di avviare la partita da un PC dotato di mouse per avere le migliori condizioni di gioco.",
      confirm_libero: "Vuoi davvero uscire dal gioco?",
      receive_mail:
        "Inserisci la tua email per ricevere in formato pdf il profilo dettagliato.",
      receive_mail_placeholder: "Scrivi qui la tua email",
      mail_success: "Mail inviata con successo!",
      mail_error: "Errore durante l'invio della mail.",
      alert_logout_1:
        "Attenzione! Uscendo non potrai più recuperare i tuoi progressi.",
      alert_logout_2: "Se in seguito vorrai rientrare, conserva questo link: ",
    },
    user: {
      welcome: "Benvenuto",
      app_description_1: `
        <b>PlayYourJob</b> è un progetto finalizzato alla riduzione della
        distanza tra domanda e offerta di lavoro, sviluppato e validato da&#32;
        <b>Laborplay</b>, spin-off approvato dell’
        <b>Università degli Studi di Firenze</b>. Partecipando al progetto ti
        restituiremo il profilo di alcune tue caratteristiche professionali.
      `,
      app_description_2: `
        Le attività <b>Performance</b> hanno un tempo massimo per essere
        svolte. Assicurati di avere il tempo sufficiente per dare
        il massimo.
        <br />
        Le attività <b>Training</b> non hanno un tempo massimo per essere
        svolte e potrai accedervi ogni volta che vorrai in momenti diversi.
      `,
      active_title: "Pacchetti attivi",
      loading: "CARICAMENTO...",
      confirm: "CONFERMA E INIZIA",
      anagraphics: {
        title: "Domande anagrafiche",
        description:
          "Compila i seguenti campi per completare le attività e salvare i tuoi risultati.",
        name: "Nome",
        name_placeholder: "Inserisci qui il tuo nome",
        surname: "Cognome",
        surname_placeholder: "Inserisci qui il tuo cognome",
        cf: "Codice Fiscale",
        cf_placeholder: "Inserisci qui il tuo Codice Fiscale",
        email_placeholder: "Inserisci qui la tua email",
        year: "Anno di nascita",
        gender: "Genere",
        male: "Maschio",
        female: "Femmina",
        frequency: "Da quanto tempo lavori?",
        never: "Meno di 2 anni",
        monthly: "Da 2 a 5 anni",
        weekly: "Da 6 a 15 anni",
        daily: "Da 16 a 25 anni",
        hourly: "Più di 25 anni",
      },
    },
    games: {
      open: "Apri",
      results: "Risultati",
      title: "Mettiti alla prova",
      title_libero: "Gioco Libero",
      close: "Chiudi",
      retry: "Riprova",
      game_order:
        "Prima di poter giocare a questo gioco devi giocare ai precedenti",
      before_rank: "Migliore del ",
      after_rank: " dei giocatori",
      test: "Vai al Test",
    },
    profile: {
      repeat: "Ripeti il test",
      own: "Il tuo profilo PLAY è: ",
      download: "Scarica il tuo profilo",
      title: "Elenco delle soft skill caratteristiche di questa area",
      description:
        "Punteggi uguali o maggiori di 4 denotano la presenza via via crescente delle seguenti capacità",
      areas,
    },
    pyt: {
      error: "Errore nel recuperare le domande. ",
      retry: "Riprova",
      description: `
        <p>
          Il Play Your Test è un originale questionario sviluppato da Laborplay e validato su un campione di oltre 25 mila persone. 
          Le domande servono a conoscere quali sono le tue abitudini e preferenze di lavoro; 
          le tue risposte consentono di elaborare un profilo delle tue competenze trasversali, 
          che ti aiuterà a riflettere sui tuoi punti di forza e aree di miglioramento.
        </p>
        <p>
          Il Play Your Test non è pensato solamente per chi lavora da tanto tempo.
        </p>
        <h4 class="superTitle" className="superTitle">Il Play Your Test è pensato per tutti.</h4>
        <h4>Perché tutti sappiamo cosa ci appassiona.</h4>
        <p>
          Rispondi pensando alle tue preferenze e se non ci hai mai pensato prima questo è il momento giusto!
          Poi indica il grado di accordo con le affermazioni che seguiranno.
        </p>
      `,
      start: "INIZIA IL TEST",
      guide: `
        <p>
        Non è importante che attualmente tu stia o non stia lavorando, ti chiediamo solo di riflettere sulle tue preferenze (anche eventuali) e indicare il grado di accordo con le seguenti affermazioni. Utilizzando la scala di misura seguente, indica quanto ti piacciono i seguenti elementi di un lavoro. 
        <br />
        <b>COSA TI PIACE (O PIACEREBBE) IN UN LAVORO?</b>
        <br />
        <i>1 = totalmente d’accordo, 2 = abbastanza d’accordo, 3 = abbastanza in disaccordo, 4 = totalmente in disaccordo</i>
        </p>
      `,
      confirm: "Conferma",
    },
    profiles: {
      Analitico: "Analitico",
      Attivatore: "Attivatore",
      Audace: "Audace",
      Brillante: "Brillante",
      Carismatico: "Carismatico",
      Cauto: "Cauto",
      Collaborativo: "Collaborativo",
      Determinato: "Determinato",
      Disponibile: "Disponibile",
      Equilibrato: "Equilibrato",
      Influente: "Influente",
      Innovatore: "Innovatore",
      Negoziatore: "Negoziatore",
      Riflessivo: "Riflessivo",
      Sistematico: "Sistematico",
      Socievole: "Socievole",
      Vivace: "Vivace",
    },
    terms,
  },
};
