import {
  createSlice,
  configureStore,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { useCallback } from "react";
import i18next from "i18next";

// Slice; rehydrate from local storage
export const slice = createSlice({
  name: "store",
  initialState: {
    loginToken: localStorage.getItem("loginToken") || "",
    attemptToken: (
      (() => {
        const hash = new URLSearchParams(window.location.search).get("hash");
        if (hash)
          window.history.replaceState(
            null,
            null,
            window.location.pathname + window.location.hash
          );
        if (
          hash &&
          localStorage.getItem("loginToken") &&
          localStorage.getItem("loginToken") !== hash
        ) {
          alert(i18next.t("login.session_warning"));
        } else return hash;
      })() || ""
    ).slice(-10), // attempt token, you can get it from url
    userData: {},
    replay: [],
    progressBeforeReplay: "",
    profileAreas: {},
    resultGame: {},
  },
  reducers: {
    loginToken: (state, { payload }) => {
      state.loginToken = payload.slice(0, 10);
    },
    attemptToken: (state, { payload }) => {
      state.attemptToken = payload.slice(0, 10);
    },
    userData: (state, { payload }) => {
      state.userData = payload;
      state.replay = JSON.parse(payload?.[0]?.replay || "[]");
      state.progressBeforeReplay = payload?.[0]?.progress_before_replay;
      Object.values(payload).forEach((g) => {
        if (g.resultGame) {
          const mode = g.resultGame["[MODE]"];
          delete g.resultGame["[MODE]"];
          g.resultGame &&
            addResultGame(state, g.path, g.resultGame, mode === "vincolato");
        }
      });
    },
    setProgressBeforeReplay: (state, { payload }) => {
      state.replay = state.replay.filter((path) => !path.includes("test"));
      state.progressBeforeReplay = payload;
    },
    profileAreas: (state, { payload }) => {
      state.profileAreas = payload;
    },
    resultGame: (state, { payload }) => {
      state.replay = state.replay.filter((path) => path !== payload.path);
      addResultGame(state, payload.path, payload.result, payload.vincolato);
    },
    reset: () => ({
      loginToken: "",
      attemptToken: "",
      userData: {},
      questionary: {},
      profileAreas: {},
      resultGame: {},
    }),
  },
});

// embed logic of resultGame action into external function for reuse
function addResultGame(state, path, result, vincolato) {
  state.resultGame[path] = Object.fromEntries(
    Object.entries(typeof result === "string" ? JSON.parse(result) : result)
      .filter(
        ([k, v]) => !k.includes(`[${vincolato ? "LIBERO" : "VINCOLATO"}]`)
      )
      .map(([k, v]) => [k.replace(/\[(LIBERO|VINCOLATO)\]/, ""), v])
      .concat([["[VAR]vincolato", vincolato]])
  );
}

// Store; middleware to save / clear localStorage
export const store = configureStore({
  reducer: slice.reducer,
  middleware: getDefaultMiddleware().concat([
    (store) => (next) => (action) => {
      const type = action.type.replace("store/", "");
      if (type === "reset") localStorage.clear();
      else if (type === "userData")
        localStorage.setItem("loginToken", store.getState().loginToken);
      return next(action);
    },
  ]),
});

// Custom hook to export selector and action
export const useStore = (prop) => [
  useSelector((state) => state[prop]),
  useCallback((action) => store.dispatch(slice.actions[prop](action)), [prop]),
];

// Custom hook to use any action
export const useAction = (defaultAction) =>
  useCallback(
    defaultAction
      ? (actionArgs) => store.dispatch(slice.actions[defaultAction](actionArgs))
      : (action, actionArgs) =>
          store.dispatch(slice.actions[action](actionArgs)),
    [defaultAction]
  );
