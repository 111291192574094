import React, { memo } from "react";
import { MemoryRouter, Link, Routes, Route } from "react-router-dom";
import { store } from "../redux/reduxStore";
import { Provider, useSelector } from "react-redux";
import {
  Help,
  AlertTerms,
  AlertValid,
  ConfirmLibero,
  ConfirmVincolato,
  ReceiveMail,
  MailSuccess,
  MailError,
  AlertLogOut,
  ShowTerms,
  LanguageOverlay,
} from "./Overlays.js";
import { LoginUser, LoginAdmin, FailedAuth, LogOut } from "./Login";
import { User } from "./User";
import { ProgressBar } from "./PYT";
import "../utils/localStorage";

import "../css/main.scss";
import LanguageSelector from "./components/LanguageSelector";
import { useNamespacedTranslation } from "./components/LanguageSelector"; 

/**
 *
 * Main app
 * You can switch between hash router and memory router;
 * memory router is the one that will be use in production, but hash is useful for debug
 * Subpaths are handled with *
 *
 */

export default function App() {
  return (
    <Provider store={store}>
      <MemoryRouter>
        <Header />
        <div id="outlet">
          <div className="rotatedWrapper">
            <div className="rotatedBG"></div>
          </div>
          <Routes>
            <Route path="/*" element={<LoginUser />} />
            <Route path="admin/*" element={<LoginAdmin />} />
            <Route path="user/*" element={<User />} />
            <Route path="failedAuth/:type" element={<FailedAuth />} />
            <Route
              path="failedAuth/:type/:inizio_validita"
              element={<FailedAuth />}
            />
          </Routes>
          <Help />
          <AlertValid />
          <AlertTerms />
          <ConfirmLibero />
          <ConfirmVincolato />
          <ReceiveMail />
          <MailSuccess />
          <MailError />
          <AlertLogOut />
          <LanguageOverlay />
          <ShowTerms />
        </div>
        <Footer />
      </MemoryRouter>
    </Provider>
  );
}

// Header, it handles choices and progress during questions
function Header() {
  const pytLanguageAndTestIcon = (
    <>
      <ProgressBar />
      <div className="toRight pyt">
        <img
          alt="Play Your Test"
          src="feature_previews/playyourtest_vincolato.svg"
        />
      </div>
      <LanguageSelector />
    </>
  );
  return (
    <header>
      <a
        id="logoLink"
        href="https://www.laborplay.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src="logo.png" alt="LaborPlay" />
      </a>
      <Routes>
        {/*PULSANTI DISABILITATI per switchare da azienda a utente*/}
        {/* <Route path="/*" element={<Link to="admin">Sei un'azienda?</Link>} />
        <Route path="admin/*" element={<Link to="/">Sei un utente?</Link>} /> */}
        <Route path="/*" element={<LanguageSelector />} />
        <Route path="user" element={<LanguageSelector />} />
        <Route path="user/:a" element={<LanguageSelector />} />
        <Route path="user/:a/:b" element={<LanguageSelector />} />
        <Route path="user/:a/:b/:c" element={<LanguageSelector />} />
        <Route path="user/:a/:b/:c/:d" element={<LanguageSelector />} />
        <Route path="user/pyt/question/*" element={pytLanguageAndTestIcon} />
        <Route path="user/pyt/question/:n/*" element={pytLanguageAndTestIcon} />
      </Routes>
    </header>
  );
}

// Footer, it handles help and logout
function Footer() {
  const { t } = useNamespacedTranslation();
  const type = useSelector((state) => state.userData?.[0]?.type);
  const userFooter = (
    <>
      <LogOut className="LogOut">EXIT</LogOut>
      {type === "nominale" && <Link to="show_terms">Privacy Policy</Link>}
      <Link className="Help" to="help">
        Help!
      </Link>
    </>
  );
  return (
    <footer>
      <Routes>
        <Route path="/*" element={<Link to="help">{t("footer.help")}</Link>} />
        <Route
          path="admin/*"
          element={<Link to="help">Help! La mia password non funziona</Link>}
        />
        <Route path="user" element={userFooter} />
        <Route path="user/:a" element={userFooter} />
        <Route path="user/:a/:b" element={userFooter} />
        <Route path="user/:a/:b/:c" element={userFooter} />
        <Route path="user/:a/:b/:c/:d" element={userFooter} />
      </Routes>
    </footer>
  );
}

// Main container with elements for rotated background
export const Container = memo((props) => (
  <div className="_container">{props.children}</div>
));
