import { ajax } from "./utils";
import { store } from "../redux/reduxStore";

// delete spurious game data from left games
Object.keys(window.localStorage).forEach(
  (key) => key.includes("_") && window.localStorage.removeItem(key)
);

// create an internal private copy of the local storage to check against
let storage = {};
for (const k in window.localStorage) {
  if (window.localStorage.hasOwnProperty(k))
    storage[k] = window.localStorage[k];
}

// save methods of localStorage
const _getItem = window.localStorage.getItem.bind(window.localStorage);
const _setItem = window.localStorage.setItem.bind(window.localStorage);
const _removeItem = window.localStorage.removeItem.bind(window.localStorage);
const _clear = window.localStorage.clear.bind(window.localStorage);

// overwrite localStorage methods to check against the private copy
export default function alterStorage(w = window, prefix = "") {
  const define = (prop, value) =>
    Object.defineProperty(w.Storage.prototype, prop, {
      value: value,
      enumerable: 0,
      configurable: 0,
      writable: 0,
    });
  define("getItem", function (k) {
    return storage[prefix + k] !== undefined ? storage[prefix + k] : null;
  });
  define("setItem", function (k, v) {
    storage[prefix + k] = v !== undefined && v !== null ? v.toString() : null;
    return _setItem(prefix + k, v);
  });
  define("removeItem", function (k) {
    delete storage[prefix + k];
    return _removeItem(prefix + k);
  });
  define("clear", function () {
    return _clear();
  });
}
alterStorage();

// check every 100ms if someone attempted to manually overwrite localStorage
/* window.setInterval(function() {
  if(!document.hidden) { // prevents detection of cheating if the tab is hidden (otherwise two tabs see each other as cheating)
    if(_getItem('loginToken')) {
      for(const i in storage) {
        if(storage.hasOwnProperty(i) && storage[i] !== _getItem(i)) {
          ajax('php/cheatAttempt.php',`loginToken=${store.getState().loginToken}&param=${i}&real_value=${storage[i]}&fake_value=${_getItem(i)}`);
          _setItem(i,storage[i]); // this doesn't work on firefox; possible solution is to not use localStorage except for loginToken; otherwise comment the line
        }
      }
    } else storage = {};
  }
}, 100); */
