import React, {
  memo,
  useEffect,
  useCallback,
  useRef,
  useMemo,
  Fragment,
  forwardRef,
  useState,
} from "react";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { updateProgress } from "../utils/utils";
import { ajax } from "../utils/utils";
import PYT from "./PYT";
import Profile from "./Profile";
import { Container } from "./App";

import "../css/user.scss";
import { useNamespacedTranslation } from "./components/LanguageSelector";

// User routes
export const User = () => {
  const goTo = useNavigate();
  const userData = useSelector((state) => state.userData);
  // navigate to progress
  useEffect(() => {
    userData[0] &&
      userData[0].progress &&
      goTo("/user/" + userData[0].progress);
  }, [goTo, userData]);
  // logout in this window if some other window made logout; make the logout button the only source of logout
  useEffect(() => {
    const i = window.setInterval(
      () =>
        !window.localStorage.getItem("loginToken") &&
        document.getElementById("clickToLogout").click(),
      1000
    );
    return () => window.clearInterval(i);
  }, [goTo]);
  // render only if user has data
  return !userData[0] ? (
    <Navigate to="/" /> // useful only for hash router, to go to login if data is not present
  ) : (
    <Routes>
      <Route path="/*" element={<Welcome />} />
      <Route path="pyt/*" element={<PYT />} />
      <Route path="profile/:progress/*" element={<Profile />} />
    </Routes>
  );
};

const IconPreview = memo((props) => (
  <img
    className="iconPreview"
    data-key={props["data-key"]}
    alt={props.feature_nome}
    src={`./feature_previews/${props.path}_${props.modalita}.svg`}
  />
));

// Welcom user page
function Welcome() {
  const userData = useSelector((state) => state.userData);
  const icons = useMemo(
    () =>
      userData.map &&
      userData.map((feature) => (
        <Fragment key={feature.path}>
          {!!parseInt(feature.vincolato) && (
            <IconPreview {...feature} modalita="vincolato" />
          )}
          {!!parseInt(feature.libero) && (
            <IconPreview {...feature} modalita="libero" />
          )}
        </Fragment>
      )),
    [userData]
  );
  // use reference for the checkbox, to see whether is checked
  const checkbox = useRef(null);
  const sex = useRef(null);
  const age = useRef(null);
  const freq = useRef(null);
  const token_name = useRef(null);
  const surname = useRef(null);
  const codice_fiscale = useRef(null);
  const email = useRef(null);
  const goTo = useNavigate();
  // starts with test or games
  const start = useSelector((state) =>
    state.userData[0].path.includes("test") ? "pyt" : "profile/home"
  );
  const nominal = useSelector((state) => state.userData[0].type === "nominale");
  const [loading, setLoading] = useState(false);
  // go to PYT and save progress, but only if the checkbox is checked
  const goToStart = useCallback(() => {
    if (
      !loading &&
      !!checkbox.current.checked &&
      /^[0-9]{4,4}$/.test(age.current.value) &&
      parseInt(age.current.value) > new Date().getFullYear() - 120 &&
      parseInt(age.current.value) < new Date().getFullYear() - 2 &&
      freq.current.querySelector(":checked") &&
      sex.current.querySelector(":checked") &&
      (!nominal ||
        (token_name.current.value &&
          surname.current.value &&
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
            email.current.value
          )))
    ) {
      setLoading(true);
      Promise.all([
        ajax("php/updateUserInfo.php", {
          loginToken: window.localStorage.getItem("loginToken"),
          age: age.current.value,
          sex: sex.current.querySelector(":checked").value,
          freq: freq.current.querySelector(":checked").value,
          nominal,
          name: token_name.current?.value,
          surname: surname.current?.value,
          codice_fiscale: codice_fiscale.current?.value,
          email: email.current?.value,
          prog: start,
        }),
        ...userData
          .filter((u) => u.path.includes("test"))
          .map((u) =>
            updateProgress(
              null,
              u.path,
              ["q0", "q0_extra", "q1"],
              [
                sex.current.querySelector(":checked").value,
                age.current.value,
                freq.current.querySelector(":checked").value,
              ]
            )
          ),
      ])
        .then((resp) => {
          // in teoria dovrebbe restituirti l'errore nella promessa
          // ma per come era concepito il sistema questo non succede quindi scriviamo l'errore a mano
          if (resp === null || (resp.includes && resp.includes(null))) {
            return Promise.reject({
              message:
                "Impossibile aggiornare i dati; forse la mail, se richiesta, è già presente nel carnet oppure uno dei campi non è valido o è stato lasciato vuoto",
            });
          }
          setLoading(false);
          goTo(start);
        })
        .catch((e) => {
          setLoading(false);
          window.alert("Errore:" + e && e.message ? e.message : e);
        });
    } else {
      goTo("alert_terms");
    }
  }, [loading, nominal, start, userData, goTo]);
    const { t } = useNamespacedTranslation();
  return (
    <Container>
      <h1 style={{ marginTop: "2rem" }}>{t("user.welcome")}</h1>
      <p
        className="appDescription"
        dangerouslySetInnerHTML={{ __html: t("user.app_description_1") }}
      />
      <div className="graySection">
        <h1 className="addTopMargin">{t("user.active_title")}</h1>
        <div className="featureIcons">{icons}</div>
        <p
          className="appDescription"
          dangerouslySetInnerHTML={{ __html: t("user.app_description_2") }}
        />
      </div>
      <Anagraphics
        ref={{ sex, age, freq, token_name, surname, email, codice_fiscale }}
      />
      <Terms ref={checkbox} />
      <Button
        disabled={loading}
        variant="danger"
        size="lg"
        onClick={goToStart}
        style={loading ? { cursor: "not-allowed" } : {}}
      >
        {t("user." + (!loading ? "confirm" : "loading"))}
      </Button>
      <div style={{ height: "3rem" }}></div>
    </Container>
  );
}

// Accept terms
const Terms = forwardRef((props, checkbox) => {
    const { t } = useNamespacedTranslation();
  const userData = useSelector((state) => state.userData[0]);
  return (
    <>
      <h1 className="addTopMargin" style={{ marginBottom: "-0.8rem" }}>
        {t("terms.title")}
      </h1>
      <div
        className="terms"
        dangerouslySetInnerHTML={{ __html: t("terms.text_" + userData.type) }}
      />

      <div className="termsRead">
        <input id="termsRead" type="checkbox" ref={checkbox} />
        <label htmlFor="termsRead">{t("terms.read")}</label>
      </div>
    </>
  );
});

const Anagraphics = forwardRef((props, ref) => {
  const { sex, age, freq, token_name, surname, codice_fiscale, email } = ref;
  const userData = useSelector((state) => state.userData[0]);
    const { t } = useNamespacedTranslation();
  return (
    <div className="box-questions-users">
      <h1 className="addTopMargin">{t("user.anagraphics.title")}</h1>
      {userData.type === "nominale" && (
        <>
          <h6
            style={{
              color: "#178AFE",
              margin: "-0.5rem 0 1.5rem 0",
              textAlign: "center",
              padding: "0 5px",
            }}
          >
            {t("user.anagraphics.description")}
          </h6>
          <div className="question">
            <div className="question questionRow">
              <h2>{t("user.anagraphics.name")}</h2>
              <input
                type="text"
                placeholder={t("user.anagraphics.name_placeholder")}
                name="token_name"
                ref={token_name}
              />
            </div>
          </div>
          <div className="question">
            <div className="question questionRow">
              <h2>{t("user.anagraphics.surname")}</h2>
              <input
                type="text"
                placeholder={t("user.anagraphics.surname_placeholder")}
                name="surname"
                ref={surname}
              />
            </div>
          </div>
          <div className="question">
            <div className="question questionRow">
              <h2 style={{ width: "6.5rem" }}>{t("user.anagraphics.cf")}</h2>
              <input
                type="text"
                placeholder={t("user.anagraphics.cf_placeholder")}
                name="surname"
                ref={codice_fiscale}
              />
            </div>
          </div>
          <div className="question">
            <div className="question questionRow">
              <h2>Email</h2>
              <input
                type="text"
                placeholder={t("user.anagraphics.email_placeholder")}
                name="email"
                ref={email}
              />
            </div>
          </div>
        </>
      )}
      <div className="question">
        <div className="question questionRow twoLines">
          <h2 style={{ width: "6.5rem" }}>{t("user.anagraphics.year")}</h2>
          <input type="text" placeholder="AAAA" ref={age} maxLength="4" />
        </div>
      </div>
      <div className="question">
        <div className="question" /* add innerbox class if needed */>
          <h2>{t("user.anagraphics.gender")}</h2>
          <div className="labels" ref={sex}>
            <label>
              <input type="radio" name="sex" value={0} />
              <span>{t("user.anagraphics.male")}</span>
            </label>
            <label>
              <input type="radio" name="sex" value={1} />
              <span>{t("user.anagraphics.female")}</span>
            </label>
          </div>
        </div>
      </div>
      <div className="question">
        <div className="question" /* add innerbox class if needed */>
          <h2>{t("user.anagraphics.frequency")}</h2>
          <div className="labels" ref={freq}>
            <label>
              <input type="radio" name="freq" value={0} />
              <span>{t("user.anagraphics.never")}</span>
            </label>
            <label>
              <input type="radio" name="freq" value={1} />
              <span>{t("user.anagraphics.monthly")}</span>
            </label>
            <label>
              <input type="radio" name="freq" value={2} />
              <span>{t("user.anagraphics.weekly")}</span>
            </label>
            <label>
              <input type="radio" name="freq" value={3} />
              <span>{t("user.anagraphics.daily")}</span>
            </label>
            <label>
              <input type="radio" name="freq" value={4} />
              <span>{t("user.anagraphics.hourly")}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
});
