export default {
  title: "Privacy terms and conditions and use of cookies",
  read: "I have read and accept the terms and conditions of use",
  text_nominale_footer: `
      <p>
      PlayYourJob is a platform of Laborplay Srl, VAT number 06563810487 with 
      registered office in: Via Madonna del Piano no. 6, Sesto Fiorentino (FI)
      email:&#32; 
      <a href="mailto:privacy@laborplay.com">privacy@laborplay.com</a> ;
      pec:&#32;
      <a href="mailto:laborplay@legalmail.it">laborplay@legalmail.it</a>.
      </p>
      <h5>
      INFORMATION ON THE PROCESSING OF PERSONAL DATA PURSUANT TO ARTICLE 13 OF EU 
      REGULATION 2016/679 (GDPR)
      </h5>
      <h6>1. Data Controller and data processing managers</h6>
      <p>
      The Data Controller is: Laborplay Srl, VAT number 06563810487, with 
      registered office in: Via Madonna del Piano no. 6, Sesto Fiorentino (FI) 
      which you can contact to exercise the rights recognized by the GDPR and to 
      find out the updated list of all the data processing managers who may have 
      been appointed.
      </p>
      <p> The Legal Representative is: Elena Gaiffi.</p>
      <p>
      The Data Controller can be contacted using the following details:
      </p>
      <p>email: privacy@laborplay.com; pec: laborplay@legalmail.it.</p>
      <p>
      The Data Controller has appointed Eng. Andrea Appicciafuoco (email: 
      rpd.appicciafuoco@gmail.com) as personal Data Protection Manager 
      ("D.P.M.").
      </p>
      <h6>2. Purpose of data processing</h6>
      <p>
      The personal data provided may be processed only for the following purposes: 
      carrying out of the service offered by the Data Controller; sending service 
      communications; fulfilment of legal obligations and compliance with requests 
      from the authorities.
      </p>
      <p>
      No further processing is foreseen based on the legitimate interests pursued 
      by the Data Controller.
      </p>
      <h6>3. Legal Basis for Data Processing</h6>
      <p>
      The Data Controller deals with your personal data lawfully, where the 
      processing:
      </p>
      <ul>
      <li>
          is necessary for the execution of a contract of which you are a party or 
          for the execution of pre-contractual measures adopted upon request;
      </li>
      <li>
          is based on the express consent of the interested party for one or more 
          purposes;
      </li>
      <li>
          is necessary to fulfil a legal obligation to which the Data Controller 
          is subject.
      </li>
      </ul>
      <h6>4. Types of data processed</h6>
      <p>
      Common data (by way of example only: year of birth, gender).
      </p>
      <p>
      The data of subjects under the minimum age required by current legislation 
      (minors) will not be processed. The Data Controller does not use cookies and 
      therefore data from their use will not be processed.
      </p>
      <h6>5. Consequences of the failure to disclose Personal Data</h6>
      <p>
      The communication of personal data is necessary in order to be able to use 
      the service offered by the Data Controller.
      </p>
      <h6>6. Communication and disclosure of the data</h6>
      <p>
      The data may be communicated only to the interested party, to the Company 
      with which the Data Controller has entered into the contract for the use of 
      the web-app owned by the Data Controller; to fulfil a legal obligation to 
      which the Data Controller is subject, or as it is necessary for the 
      execution of a task of public interest in which the Data Controller is 
      involved. Within the limits relevant to the processing purposes indicated 
      above, your data may be disclosed to:
      </p>
      <ul>
      <li>
          the Company that has entered into the contract for the use of the web-
          app owned by the Data Controller;
      </li>
      <li>
          employees or partners of the Data Controller qualified as "appointees";
      </li>
      <li>
          Judicial or administrative authorities to fulfil legal obligations.
      </li>
      </ul>
      <p>
      The data will not be disclosed. The data will not be transferred to a third 
      country or international organisation.
      </p>
      <h6>7. Profiling</h6>
      <p>
      Your personal data will be subject to profiling, only with your express 
      consent. Profiling is obtained through the use by the User of the 
      application owned by the Data Controller.
      </p>
      <h6>8. Processing methods </h6>
      <p>
      The processing of personal data consists in the collection, registration, 
      organisation, structuring, conservation, adaptation or modification, 
      extraction, consultation, use, communication, disclosure, comparison or 
      interconnection, limitation, cancellation or destruction of the data in 
      question.
      </p>
      <p>
      The processing of personal data is carried out for the aforementioned 
      purposes, in accordance with the provisions of Article 5 of the GDPR, on:
      </p>
      <ul>
      <li>
          IT devices (including portable ones) and by telematic means (i.e. 
          email); in compliance with the provisions of art. 32 of the GDPR  
          regarding security measures and in compliance with the provisions of 
          art. 29 of the GDPR.
      </li>
      </ul>
      <h6>9. Data retention</h6>
      <p>
      Your data will be stored in the "cloud", on servers located within the 
      European Union, and will be kept for the time necessary to achieve the 
      purposes indicated.
      </p>
      <p>
      Your data will be kept for the duration of the contract stipulated and 
      concerning the use of the web-app owned by the Data Controller and, 
      subsequently, kept for a further 30 days starting from the expiry of the 
      aforementioned contract. After the aforementioned term, your personal data 
      will be deleted;
      </p>
      <h6>10. Rights of the Data Subject</h6>
      <p> You are entitled to: </p>
        <ul>
        <li> to ask the data controller for access to personal data </li>
        <li> the correction of inaccurate data or the integration of incomplete ones </li>
        <li> to the possible cancellation of your personal data concerning you (upon the occurrence of one of the conditions indicated in art.17, paragraph 1, RGPD, and in compliance with the exceptions provided for in paragraph 3 of the same article), without prejudice to the provisions at point 9 of this information notice; </li>
        <li> the possible limitation of the processing of your personal data; </li>
        <li> if the processing is carried out by automated (computer) means and to the extent that it is technically possible, to receive in a structured format or to transmit to him or to third parties indicated by him the information concerning him, </li>
        <li> to withdraw your consent at any time (without prejudice to the lawfulness of the treatment based on consent before the revocation), obviously for the treatments carried out on the basis of this assumption </li>
        <li> if the response to your requests is not satisfactory or if you deem it necessary, to lodge a complaint with the Guarantor for the Protection of Personal Data (http://www.garanteprivacy.it/) - Piazza Venezia n. 11 00186 ROME - Telephone switchboard: (+39) 06.696771 - E-mail: garante@gpdp.it - ​​certified mail protocol@pec.gpdp.it </li>
        </ul>
        <p>
        To assert their rights, the interested party may contact the Data Controller directly via the contact details indicated above, bearing in mind that it will not be possible to respond to requests received by telephone if there is no certainty about the identity of the applicant.
        </p>
  `,
  text_nominale: `
      <p>
      PlayYourJob is a platform of Laborplay Srl, VAT number 06563810487 with 
      registered office in: Via Madonna del Piano no. 6, Sesto Fiorentino (FI)
      email:&#32;
      <a href="mailto:privacy@laborplay.com">privacy@laborplay.com</a> ;
      pec:&#32;
      <a href="mailto:laborplay@legalmail.it">laborplay@legalmail.it</a>.
      </p>
      <h5>INFORMATION ON THE PROCESSING OF PERSONAL DATA</h5>
      <h6>1) WHAT IS MEANT BY DATA PROCESSING?</h6>
      <p>
      It means the collection, registration, organisation, structuring, 
      conservation, adaptation or modification, extraction, consultation, use, 
      communication, disclosing, comparison or interconnection, limitation, 
      cancellation or destruction of the data.
      </p>
      <h6>2) WHO PROCESSES MY PERSONAL DATA?</h6>
      <p>
      The Data Controller, which is Laborplay Srl, with registered office in Via 
      Madonna del Piano no. 6, Sesto Fiorentino (FI), VAT number 06563810487 
      email: privacy@laborplay.com; certified email: laborplay@legalmail.it
      </p>
      <h6>3) FOR WHAT PURPOSES ARE MY DATA PROCESSED?</h6>
      <p>
      To perform the service offered by the Data Controller; to send you 
      communications regarding the service itself; to fulfil legal obligations and 
      comply with requests from the Authorities.
      </p>
      <h6>4) WHAT IS THE LEGAL BASIS FOR THE PROCESSING OF MY DATA?</h6>
      <p>
      We process your data lawfully whenever the processing is necessary to 
      execute the contract to which you are a party or to carry out pre-
      contractual measures adopted at your request; it is based on your express 
      consent, where required; it is necessary to fulfil a legal obligation to 
      which the Data Controller is subject; it is necessary for the pursuit of the 
      legitimate interest of the Data Controller. The legitimate interests of the 
      Data Controller include its protection, that of the interested parties or 
      other subjects from security threats or fraud, in compliance with current 
      legislation; the management and improvement of the company.
      </p>
      <h6>5) WHICH PERSONAL DATA ARE PROCESSED?</h6>
      <ul>
        <li> Common data provided by the interested party or by subjects authorized by them-delegates such as, by way of example, gender and year of birth. </li>
        <li> Common data entered during the compilation of the test. </li>
        <li> Laborplay® Internally Generated Ratings and Profiles. </li>
        </ul>
        <p>
        The data of minors will be processed with the informed and express consent of those who exercise parental authority.
        </p>
        <p>
        The Data Controller does not use cookies and therefore data from their use will not be processed.
        </p>
      <h6>6) WHAT HAPPENS IF I DON'T PROVIDE MY PERSONAL DATA? </h6>
      <p>
      Failure to communicate your data will not allow you to use the service 
      offered by the Data Controller.
      </p>
      <h6>7) WHO WILL MY DATA BE COMMUNICATED TO?</h6>
      <p>
      Your data may be disclosed, within the limits relevant to the purposes 
      indicated above:
      </p>
      <p>
      to you, to the Company with which the Data Controller has entered into the 
      contract for the use of the web-app owned by the Data Controller; to fulfil 
      a legal obligation to which the Data Controller is subject, or as it is 
      necessary for the execution of a task of public interest in which the Data 
      Controller is involved.
      </p>
      <p>
      Within the limits relevant to the processing purposes indicated above, your 
      data may be disclosed to:
      </p>
      <ul>
      <li>
          the Company that has entered into the contract for the use of the web-
          app owned by the Data Controller;
      </li>
      <li>
          employees or partners of the Data Controller qualified as "appointees";
      </li>
      <li>
          judicial or administrative authorities to fulfil legal obligations;      
      </li>
      <li>
          subjects appropriately designated "Data processing managers" by the Data 
          Controller or who provide functional services for the purposes indicated 
          above;
      </li>
      <li>
          subjects that process data in execution of specific legal obligations;
      </li>
      </ul>
      <p> Your data will NOT be disclosed.</p>
      <p>
      Your data will NOT be transferred to a country not belonging to the European 
      Union or to an international organisation;
      </p>
      <h6>8) HOW ARE MY DATA PROCESSED? ARE THEY PROFILED?</h6>
      <p>
      The processing of your personal data is carried out on IT devices (including 
      portable ones) in compliance with the provisions of current legislation.
      </p>
      <p>
      Your personal data will be subject to profiling, only with your express 
      consent. Profiling is obtained through the use by the User of the 
      application owned by the Data Controller.
      </p>
      <h6>9) HOW LONG WILL MY DATA BE KEPT?</h6>
      <p>
      Your data will be stored in the "cloud", on servers located within the 
      European Union, and will be kept for the time necessary to achieve the 
      purposes indicated.
      </p>
      <p>
      Your data will be kept for the duration of the contract signed with the 
      Company for the use of the web-app owned by the Data Controller and, 
      subsequently, kept for a further 30 days starting from the expiry of the 
      aforementioned contract. After the aforementioned term, your personal data 
      will be deleted.
      </p>
      <h6>10) WHAT ARE MY RIGHTS UNDER THE PRIVACY LAW IN FORCE?</h6>
      <p> You are entitled to: </p>
        <ul>
        <li> to ask the data controller for access to personal data </li>
        <li> the correction of inaccurate data or the integration of incomplete ones </li>
        <li> to the possible cancellation of your personal data concerning you (upon the occurrence of one of the conditions indicated in art.17, paragraph 1, RGPD, and in compliance with the exceptions provided for in paragraph 3 of the same article), without prejudice to the provisions at point 9 of this information notice; </li>
        <li> the possible limitation of the processing of your personal data; </li>
        <li> if the processing is carried out by automated (computer) means and to the extent that it is technically possible, to receive in a structured format or to transmit to him or to third parties indicated by him the information concerning him, </li>
        <li> to withdraw your consent at any time (without prejudice to the lawfulness of the treatment based on consent before the revocation), obviously for the treatments carried out on the basis of this assumption </li>
        <li> if the response to your requests is not satisfactory or if you deem it necessary, to lodge a complaint with the Guarantor for the Protection of Personal Data (http://www.garanteprivacy.it/) - Piazza Venezia n. 11 00186 ROME - Telephone switchboard: (+39) 06.696771 - E-mail: garante@gpdp.it - ​​certified mail protocol@pec.gpdp.it </li>
        </ul>
        <p>
        To assert their rights, the interested party may contact the Data Controller directly via the contact details indicated above, bearing in mind that it will not be possible to respond to requests received by telephone if there is no certainty about the identity of the applicant.
        </p>
  `,
  text_anonimo: `
      <p>
      PlayYourJob is a platform of Laborplay Srl, VAT number 06563810487 with 
      registered office in: Via Madonna del Piano no. 6, Sesto Fiorentino (FI)
      email:&#32;
      <a href="mailto:privacy@laborplay.com">privacy@laborplay.com</a> ;
      pec:&#32;
      <a href="mailto:laborplay@legalmail.it">laborplay@legalmail.it</a>.
      </p>
      <p>
      PlayYourJob <b>does not collect information and personal data</b> or data 
      that would allow the identity of the person having provided them to be 
      traced. Access to the platform takes place through the use of anonymous 
      tokens and log-in details automatically generated by the system on behalf of 
      a company requesting the service. The information entered on the PlayYourJob 
      platform (gender, age, game preferences) is generic and does not allow for 
      the tracing of the identity of the respondent. The data provided may be 
      processed only for the following purposes: execution of the PlayYourJob 
      service with particular reference to the use of games and participation in 
      the profiling questionnaire in order to carry out automatic job/match 
      profiling on behalf of the Company requesting the service. The company 
      requesting the service is responsible for matching the tokens to users and 
      is the only one who knows their personal information (name, surname, email, 
      etc.). The user's data and information are processed by Laborplay and 
      returned anonymously relating to an individual token to the Company that 
      requested the service, except in the case where Laborplay has been expressly 
      appointed as data processing manager in accordance with the law. The cookies 
      used are used to optimise the user experience and facilitate navigation 
      between pages. The data and responses provided are stored in accordance with 
      the law on AWS Amazon servers located within the EU on behalf of Laborplay.
      </p>
  `,
};
