import React, {
  Fragment,
  memo,
  useMemo,
  useCallback,
  useEffect,
  useState,
  useRef,
} from "react";
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { gameConfirm } from "./Overlays.js";
import { tryJson, updateProgress } from "../utils/utils";
import { useAction } from "../redux/reduxStore";
import { ChartRank } from "../svg/chartRank";
import { useResultGame, useProgressInfo } from "./hooks";

import "../css/games.scss";
import timestamp from "../utils/timestamp.js";
import { useNamespacedTranslation } from "./components/LanguageSelector.jsx";
import { useGetLanguage } from "./components/LanguageSelector.jsx";

// Show games
export default function Games() {
  const { t } = useNamespacedTranslation();
  const language = useGetLanguage();
  const goTo = useNavigate();
  const { progress } = useParams();
  const goToGame = useCallback(
    (e) => goTo(`${e.target.getAttribute("data-path")}/start`),
    [goTo]
  );
  const goToRetry = useCallback(
    (e) => goTo(`${e.target.getAttribute("data-path")}/retry`),
    [goTo]
  );
  // get data
  const userData = useSelector((state) => state.userData);
  const replay = useSelector((state) => state.replay);
  const resultGame = useSelector((state) => state.resultGame);
  // create game previews and routes
  const [gamePreviews, gameRoutes] = useMemo(() => {
    // get features that are not tests, and then vincolato games
    const games = userData.filter((g) => !g.path.includes("test"));
    const gamesVincolato = games.filter((g) => !!parseInt(g.vincolato));
    // get index for the current path
    const pathIndex = 1 + gamesVincolato.findIndex((g) => g.path === progress);
    return [
      // game previews
      games.map((g, i) => {
        const toReplay = replay.includes(g.path);
        const showAsVincolato =
          toReplay ||
          (!!parseInt(g.vincolato) &&
            (!parseInt(g.libero) ||
              gamesVincolato.findIndex((gV) => gV === g) > pathIndex - 1));
        const onlyShowResults =
          !toReplay && !parseInt(g.libero) && resultGame[g.path];
        return (
          <div key={g.path} className="gamePreview">
            <div className="gameCaption">
              <img
                src={`feature_previews/${g.path}_${
                  showAsVincolato ? "vincolato" : "libero"
                }.svg`}
                alt={g.feature_nome}
              />
              {onlyShowResults ? (
                <Button
                  variant="danger"
                  data-path={g.path}
                  className="grafico-icon onlyShowResults"
                  onClick={goToRetry}
                >
                  {t("games.results")}
                </Button>
              ) : (
                <Button variant="danger" data-path={g.path} onClick={goToGame}>
                  {t("games.open")}
                </Button>
              )}
            </div>
            <p
              dangerouslySetInnerHTML={{
                __html:
                  tryJson(g.feature_descrizione)?.[language] ??
                  g.feature_descrizione ??
                  "",
              }}
            />
          </div>
        );
      }),
      // game routes
      games.map((g) => {
        const toReplay = replay.includes(g.path);
        // libero and vincolato are two columns on the database, with 0 and 1 as possible values
        const libero = parseInt(g["libero"]);
        const vincolato = parseInt(g["vincolato"]);
        // isDisabled depends on the modalities chosen for the game
        let isDisabled;
        if (toReplay) isDisabled = false;
        else if (!libero && vincolato)
          isDisabled = gamesVincolato.indexOf(g) !== pathIndex;
        else if (libero && !vincolato)
          isDisabled = gamesVincolato.length !== pathIndex;
        else if (libero && vincolato)
          isDisabled = gamesVincolato.indexOf(g) > pathIndex;
        // isAfter says if the game is yet to play
        const isAfter =
          isDisabled &&
          games.indexOf(g) > games.findIndex((g) => g.path === progress);
        // prendi il tempo della feature, potrebbe essere utile per correggere l'overlay
        const initialTime =
          JSON.parse(g.json_extra || g.feature_json || "{}").time || 1200;
        return (
          <Fragment key={g.path}>
            <Route
              path={`${g.path}/start/*`}
              element={
                <StartGame
                  path={g.path}
                  game={g.feature_nome}
                  instructions={
                    tryJson(g.istruzioni)?.[language] ?? g.istruzioni ?? ""
                  }
                  initialTime={initialTime}
                  isDisabled={isDisabled}
                  isAfter={isAfter}
                ></StartGame>
              }
            />
            <Route
              path={`${g.path}/retry`}
              element={
                <RetryGame path={g.path} game={g.feature_nome}></RetryGame>
              }
            />
            <Route
              path={`${g.path}/play/*`}
              element={
                <PlayGame path={g.path} game={g.feature_nome}></PlayGame>
              }
            />
          </Fragment>
        );
      }),
    ];
  }, [
    userData,
    progress,
    replay,
    resultGame,
    goToRetry,
    t,
    goToGame,
    language,
  ]);
  // show component
  return gamePreviews.length ? (
    <>
      <div className="graySection">
        <h1 className="addMargin">{t("games.title")}</h1>
        {gamePreviews}
      </div>
      <Routes>{gameRoutes}</Routes>
    </>
  ) : (
    <></>
  );
}

// Start Game Overlay
const StartGame = memo((props) => {
  const { t } = useNamespacedTranslation();
  const goTo = useNavigate();
  const closeOverlay = useCallback(() => goTo("../../"), [goTo]);
  const instructions = useMemo(() => ({ __html: props.instructions }), [props]);
  const { currentGameIsVincolato, currentGame } = useProgressInfo(props);
  const playGame = useCallback(() => {
    if (currentGameIsVincolato) {
      window._confirm_vincolato_time_ = props.initialTime || 1200;
      document.dispatchEvent(
        new CustomEvent("_confirm_vincolato_", { detail: {} })
      );
      goTo("confirm_vincolato");
      gameConfirm().then((confirmed) => confirmed && goTo("../play"));
    } else goTo("../play");
  }, [goTo, currentGameIsVincolato]);
  const resultGame = useResultGame(props.path);
  const goResults = useCallback(() => goTo("../retry"), [goTo]);
  return (
    <div className="overlayGame">
      <img
        alt={props.game}
        src={`./feature_previews/${props.path}_${
          currentGameIsVincolato || !parseInt(currentGame.libero)
            ? "vincolato"
            : "libero"
        }.svg`}
      />
      {!props.isDisabled && (
        <Button
          variant="danger"
          size="lg"
          className="playVariant"
          onClick={playGame}
        >
          Gioca
        </Button>
      )}
      {props.isAfter && <h5>{t("games.game_order")}</h5>}
      {resultGame && (
        <Button
          variant="danger"
          size="lg"
          className="grafico-icon onlyShowResults retryVariant"
          onClick={goResults}
        >
          {t("games.results")}
        </Button>
      )}
      <div
        className="instructions"
        dangerouslySetInnerHTML={instructions}
      ></div>
      <Button
        variant="danger"
        className="noArrowIcon closeOverlay"
        onClick={closeOverlay}
      >
        {t("games.close")}
      </Button>
    </div>
  );
});

// Play Game Overlay
const PlayGame = memo((props) => {
  const userData = useSelector((state) => state.userData);
  // get features that are not tests, and then vincolato games
  const { t } = useNamespacedTranslation();
  const goTo = useNavigate();
  // ref for the current frame
  const ref = useRef();
  // get progress info
  const { progress, currentGameIsVincolato, nextIsTest, toReplay } =
    useProgressInfo(props);
  // take 1200 seconds as time (20 minutes) or time in json extra
  const jsonExtra = JSON.parse(
    userData.find((g) => g.path === props.path).feature_json || "{}"
  );
  const initialTime = jsonExtra.time || 1200;
  const [time, setTime] = useState(initialTime);
  // get action to set results of the game
  const setResultGame = useAction("resultGame");
  // change time every second and also give a clear effect function
  // also allow to force the time from the game
  useEffect(() => {
    const i = setInterval(() => {
      const forceTime = ref.current.contentWindow.PYJ_FORCE_TIME;
      setTime(typeof forceTime !== "undefined" ? forceTime : time - 1);
    }, 1000);
    return () => clearInterval(i);
  }, [setTime, time]);
  // format time as mm:ss
  const format = useMemo(() => {
    const date = new Date(null);
    date.setSeconds(time);
    return (
      date.getUTCMinutes().toString().padStart(2, "0") +
      ":" +
      date.getUTCSeconds().toString().padStart(2, "0")
    );
  }, [time]);
  // save in storage and in db if game is vincolato; also save timestamp
  useEffect(() => {
    const startData = {
      modalita: currentGameIsVincolato ? "vincolato" : "libero",
      timestamp: timestamp(),
    };
    if (ref.current.contentWindow.playyourjob_storage) {
      console.log("C'E STORAGE");
      if (!ref.current.contentWindow.playyourjob_storage.modalita) {
        console.log("NON C'E MODALITA");
        ref.current.contentWindow.playyourjob_storage.modalita =
          startData.modalita;
        ref.current.contentWindow.playyourjob_storage.timestamp =
          startData.timestamp;
      }
    } else {
      console.log("NON C'E STORAGE, RICREO");
      ref.current.contentWindow.playyourjob_storage = startData;
    }
  }, [ref, props, currentGameIsVincolato]);
  // get language
  const language = useGetLanguage();
  // handle time end and periodic save
  useEffect(() => {
    // end time
    const end = currentGameIsVincolato
      ? process.env.NODE_ENV !== "production" // end time of a vincolato game; close automatically after time reaches end
        ? 0 //initialTime - 20 // set higher for test and development purposes
        : 0 // in production it must be 0
      : -Infinity; // infinite time is the end of a libero game; it must be closed manually because of this
    // if time has ended or if periodic save to do
    if (time === end || time % 10 === 0) {
      // calculate keys and values;
      const keys = Object.keys(ref.current.contentWindow.playyourjob_storage);
      const values = keys.map(
        (k) => ref.current.contentWindow.playyourjob_storage[k]
      );
      // create fine timestamp
      ref.current.contentWindow.playyourjob_storage.timestamp_fine =
        timestamp();
      // currentProgress becomes the path of the current game only if current game was the next vincolato one
      const currentProgress =
        currentGameIsVincolato && !nextIsTest ? props.path : progress;
      let nextProgressGameOrTest = nextIsTest
        ? "pyt"
        : `profile/${currentProgress}`;
      // BUT dont progress if this was a replay
      if (toReplay) nextProgressGameOrTest = "";
      // if time has ended, save progress and also path, then change path and delete store
      if (time === end) {
        updateProgress(nextProgressGameOrTest, props.path, keys, values).then(
          (resp) => {
            if (resp) {
              // set data
              setResultGame({
                path: props.path,
                result: resp,
                vincolato: currentGameIsVincolato,
              });
            }
          }
        );
        goTo(`../../../${currentProgress}/${props.path}/retry`);
        // else only update progress and path
      } else updateProgress(nextProgressGameOrTest, props.path, keys, values);
    }
  }, [
    ref,
    goTo,
    time,
    props,
    currentGameIsVincolato,
    progress,
    setResultGame,
    nextIsTest,
    toReplay,
  ]);
  // click handler
  const closeLibero = useCallback(() => {
    goTo("confirm_libero");
    gameConfirm().then((confirmed) => confirmed && setTime(-Infinity));
  }, [goTo, setTime]);
  // render
  return (
    <div className="overlayGame">
      {currentGameIsVincolato ? (
        <div className="counterGame">Countdown: {format}</div>
      ) : (
        <div className="liberoGame">
          <h5>{t("games.title_libero")}</h5>
          <Button
            variant="danger"
            className="noArrowIcon closeOverlay"
            onClick={closeLibero}
          >
            {t("games.close")}
          </Button>
        </div>
      )}
      <iframe
        ref={ref}
        src={`${window.location.origin}/features/${props.path}/?lang=${
          language || "it"
        }`}
        title={props.game}
      ></iframe>
    </div>
  );
});

// Retry Game Overlay
const RetryGame = memo((props) => {
  const { t } = useNamespacedTranslation();
  const goTo = useNavigate();
  const { nextIsTest } = useProgressInfo(props);
  const isLibero = useSelector(
    (state) =>
      !!parseInt(state.userData.find((g) => g.path === props.path)["libero"])
  );
  const canBeRetried = isLibero;
  const resultGame = useResultGame(props.path);
  const isSurvey =
    props.path.indexOf("s_") === 0 || props.path.indexOf("survey_") === 0
      ? true
      : null;
  // close and eliminate from replay
  const closeOverlay = useCallback(() => {
    goTo("../../");
  }, [goTo]);
  const playTest = useCallback(() => goTo("/user/pyt"), [goTo]);
  const playGame = useCallback(() => goTo("../play"), [goTo]);
  const results = useMemo(
    () =>
      Object.entries(resultGame || {}).map(([k, v]) =>
        !k.includes("[VAR]") &&
        (isLibero || k.toLowerCase().includes("standard")) ? (
          <div key={k}>
            <b>{k}</b>
            <span>: {v}</span>
          </div>
        ) : null
      ),
    [isLibero, resultGame]
  );
  return (
    <div className="overlayGame resultParent">
      <div className="scrollResult">
        {resultGame && (
          <img
            alt={props.game}
            src={`./feature_previews/${props.path}_${
              resultGame["[VAR]vincolato"] ? "vincolato" : "libero"
            }.svg`}
          />
        )}
        {!isSurvey &&
        resultGame &&
        resultGame["[VAR]vincolato"] &&
        resultGame["[VAR]rango"] ? (
          <>
            <ChartRank standard={parseInt(resultGame["[VAR]standard"])} />
            <p className="numericRank">
              {t("games.before_rank")}
              <b>{resultGame["[VAR]rango"]}%</b>
              {t("games.after_rank")}
            </p>
          </>
        ) : (
          ""
        )}
        {!isSurvey && <div className="resultGame">{results}</div>}
        {isSurvey && (
          <div style={{ padding: "2rem" }}>
            Grazie per aver compilato il test.
            <br />
            <br />
            {/* NO! dovrebbe essere un testo in db in una nuova colonna */}
            Puoi contare sullo staff dell’area psicologico-educativa della
            squadra per approfondire i risultati.
          </div>
        )}
        {nextIsTest && (
          <Button
            variant="danger"
            size="lg"
            className="noArrowIcon"
            onClick={playTest}
          >
            {t("games.test")}
          </Button>
        )}
        {!isSurvey && canBeRetried && (
          <Button
            variant="danger"
            size="lg"
            className="noArrowIcon"
            onClick={playGame}
          >
            {t("games.retry")}
          </Button>
        )}
        {!nextIsTest && (
          <Button
            variant="danger"
            className="noArrowIcon closeOverlay"
            onClick={closeOverlay}
          >
            {t("games.close")}
          </Button>
        )}
      </div>
    </div>
  );
});
