import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

// hook to get the result of the game
export function useResultGame(path) {
  return useSelector((state) => state.resultGame[path]);
}

// custom hook to get progress info
export function useProgressInfo(props) {
  const { progress } = useParams();
  // get vincolato games
  const gamesVincolato = useSelector((state) =>
    state.userData.filter(
      (g) => !!parseInt(g.vincolato) && !g.path.includes("test")
    )
  );
  const toReplay = useSelector((state) => state.replay.includes(props.path));
  // determine if current game is next in vincolato series
  const currentGameIsVincolato = useMemo(
    () =>
      toReplay ||
      gamesVincolato.findIndex((g) => g.path === props.path) >
        gamesVincolato.findIndex((g) => g.path === progress),
    [gamesVincolato, progress, props]
  );
  // determine if next feature is a test
  const nextIsTest = useSelector((state) => {
    if (state.replay.includes(props.path)) return false;
    const currIndex = state.userData.findIndex((f) => f.path === props.path);
    return !!state.userData.find(
      (f, i) =>
        i > currIndex && !!parseInt(f.vincolato) && f.path.includes("test")
    );
  });
  const currentGame = useSelector((state) =>
    state.userData.find((g) => g.path === props.path)
  );
  // return object with all the info
  return {
    progress,
    gamesVincolato,
    currentGameIsVincolato,
    nextIsTest,
    currentGame,
    toReplay,
  };
}
