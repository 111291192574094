import terms from "./terms/es";
import areas from "./areas/es";

export default {
  translation: {
    login: {
      title: "Cómo acceder",
      insert:
        "Ingrese el código alfanumérico de 10 dígitos que se le proporcionó:",
      or: "o",
      qr: "Escanee el código QR",
      qr_back: "Volver atrás",
      exit: "Salir",
      retry: "Reintentar",
      failed_1: "Error de autenticación.",
      failed_2:
        "Asegúrese de escribir el token correctamente en mayúsculas y minúsculas.",
      failed_active:
        "Su token aún no está activo. Será efectivo a partir de la siguiente fecha:",
      failed_expired: "Su token ya no es válido.",
      session_warning:
        'Ya tienes una sesión abierta localmente. Si desea finalizar la sesión actual e iniciar una nueva, presione "EXIT". De lo contrario, ignore esta advertencia.',
    },
    footer: {
      help: "¡Auxilio! Mi código o QR no funciona",
    },
    overlay: {
      close: "CERRAR",
      undo: "CANCELAR",
      help: "¿Necesita ayuda? Escriba un correo electrónico a:",
      alert_valid: "¡Elija una opción válida antes de continuar!",
      alert_terms:
        "¡Antes de continuar, debes responder a las preguntas personales y aceptar los términos y condiciones! Verifica también que has introducido los datos correctamente.",
      confirm_vincolato:
        "Está a punto de comenzar el juego. Una vez que haga clic en Aceptar, tendrá 20 minutos para obtener la mejor puntuación. Asegúrese de haber leído y comprendido todas las instrucciones detenidamente. También asegúrese de tener tiempo, una conexión estable y un lugar cómodo y tranquilo. Le sugerimos que inicie el juego desde una PC equipada con un mouse para tener las mejores condiciones de juego.",
      confirm_libero: "¿De verdad desea salir del juego?",
      receive_mail:
        "Ingrese su correo electrónico para recibir el perfil detallado en formato pdf.",
      receive_mail_placeholder: "Escriba su correo electrónico aquí",
      mail_success: "¡Correo enviado con éxito!",
      mail_error: "Error al enviar el correo electrónico.",
      alert_logout_1:
        "¡Atención! Si sale, ya no podrá recuperar sus progresos.",
      alert_logout_2:
        "Si desea volver a ingresar más tarde, mantenga este enlace: ",
    },
    user: {
      welcome: "Bienvenido/a",
      app_description_1: `
        <b>PlayYourJob</b> es un proyecto destinado a reducir el desequilibrio
        entre la oferta y la demanda de empleo que ha sido desarrollado y validado 
        por <b>Laborplay</b>, entidad colaboradora aprobada por la <b>Universidad de Florencia</b>.
        Al participar en el proyecto, crearemos el perfil de algunas de tus características
        profesionales.
      `,
      app_description_2: `
        Las actividades de <b>desempeño</b> se realizan en un tiempo máximo.
        Asegúrate de que dispones del tiempo suficiente para rendir al máximo.
        Las actividades de <b>formación</b> y el PlayYourTest no tienen límite de tiempo para su
        realización y podrás acceder a ellos siempre que lo desees.
      `,
      active_title: "Paquetes activos",
      loading: "CARGANDO...",
      confirm: "CONFIRMAR Y EMPEZAR",
      anagraphics: {
        title: "Solicitud de datos personales",
        description:
          "Rellena los siguientes campos para completar las actividades y guardar todos tus resultados.",
        name: "Nombre",
        name_placeholder: "Introduce aquí tu nombre",
        surname: "Apellidos",
        surname_placeholder: "Introduce aquí tus apellidos",
        cf: "Número de Seguridad Social",
        cf_placeholder: "Introduce aquí tu Número de Seguridad Social",
        email_placeholder: "Introduce aquí tu correo electrónico",
        year: "Año de nacimiento",
        gender: "Género",
        male: "Hombre",
        female: "Mujer",
        frequency: "¿Cuánto tiempo llevas trabajando?",
        never: "Menos de 2 años",
        monthly: "De 2 a 5 años",
        weekly: "De 6 a 15 años",
        daily: "De 16 a 25 años",
        hourly: "Más de 25 años",
      },
    },
    games: {
      open: "Abrir",
      results: "Resultados",
      title: "Póngase a prueba",
      title_libero: "Juego libre",
      close: "Cerrar",
      retry: "Reintentar",
      game_order: "Antes de jugar a este juego debe jugar a los anteriores",
      before_rank: "Mejor que ",
      after_rank: " de los jugadores",
      test: "Ir a la prueba",
    },
    profile: {
      repeat: "Repetir la prueba",
      own: "Tu perfil PLAY es: ",
      download: "Descarga tu perfil",
      title: "Lista de habilidades técnicas características de esta área",
      description:
        "Las puntuaciones iguales o superiores a 4 indican la presencia creciente de las siguientes capacidades ",
      areas,
    },
    pyt: {
      error: "Error al recuperar preguntas.",
      retry: "Reintentar",
      description: `
        <p>
          El Play Your Test es un cuestionario original desarrollado por Laborplay y validado en una muestra de más de 25,000 personas. 
          Las preguntas están diseñadas para entender tus hábitos y preferencias de trabajo; 
          tus respuestas permiten elaborar un perfil de tus competencias transversales, 
          lo cual te ayudará a reflexionar sobre tus fortalezas y áreas de mejora.
        </p>
        <p>
          El Play Your Test no está pensado solamente para quienes llevan mucho tiempo trabajando.
        </p>
        <h4 class="superTitle" class="superTitle" className="superTitle">Play Your Test está diseñado para todos.</h4>
        <p>
          Porque todos sabemos qué nos apasiona.
        </p>
        <p>
          Responde pensando en tus preferencias y si nunca lo has pensado antes, ¡ahora es el momento adecuado! 
          Luego indica el grado de acuerdo con las afirmaciones que seguirán.
        </p>
      `,
      start: "INICIAR LA PRUEBA",
      guide: `
        <p>
        No importa si trabajas actualmente o no, sólo te pedimos que reflexiones sobre tus preferencias (también posibles) e indiques en qué medida estás de acuerdo con las siguientes afirmaciones. Utilizando la siguiente escala, indica cuánto te gustan los siguientes elementos de un trabajo.
        <br />
        <b>¿QUÉ TE GUSTA (O TE GUSTARÍA) EN UN TRABAJO?</b>
        <br />
        <i>1 = totalmente de acuerdo, 2 = bastante de acuerdo, 3 = bastante en desacuerdo, 4 = totalmente en desacuerdo</i>
        </p>
      `,
      confirm: "Conferma",
    },
    profiles: {
      Analitico: "Analitico",
      Attivatore: "Activador",
      Audace: "Atrevido",
      Brillante: "Brillante",
      Carismatico: "Carismático",
      Cauto: "Cauteloso",
      Collaborativo: "Colaborativo",
      Determinato: "Decidivo",
      Disponibile: "Disponible",
      Equilibrato: "Equilibrado",
      Influente: "Influyente",
      Innovatore: "Innovador",
      Negoziatore: "Negociador",
      Riflessivo: "Reflexivo",
      Sistematico: "Sistemático",
      Socievole: "Sociable",
      Vivace: "Vivaz",
    },
    terms,
  },
};
